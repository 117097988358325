import React, { Component } from 'react';
import './CisIndicesBanner.css';
import { getImgUrl } from '../../utils/imgUrlUtils';
import utils from '../../components/cisCommon/utils';
import { getContentByBusinessId,getContentById } from '../../services/content';
import CisVideo from './cisVideo';
import {withRouter} from 'react-router-dom';
import { getPageByName } from './../../services/page';

class CisIndicesBanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            indicesData: null,
            showingLink:''
        }
    }


    componentDidMount = () => {
        
        this.getDataFromServer( true)
    }

    getDataFromServer = async ( isInit) => {
        const {index:mqaTicker} = this.props.match.params

        const videoAutoplay = window.location.search.slice(1)
        if (mqaTicker) {
            const res = await getContentByBusinessId(mqaTicker,'carousel');
            if (res.success === 1 && res.data ) {
                const newState = { indicesData: res.data };
                if (isInit) {
                    newState.isShowVideo = videoAutoplay === "video=on" ? true : false;
                }
                this.setState(newState);
            } else {
                console.log(`get contents by business id fail, message: ${res.msg}, data: ${res.data}`);
                const rsp = await getPageByName('CIS-IndiceBanner');
                if (rsp.success === 1 && rsp.data) {
                    try{

                        const config = rsp.data.uiRows[0].uiCols[0].portlet.config_json
                        const configObj = JSON.parse(config)
                        const defaultId = configObj.defaultItem
                        const res = await getContentById(defaultId);
                        if (res.success === 1 && res.data ) {
                            const newState = { indicesData: res.data };
                            if (isInit) {
                                newState.isShowVideo = videoAutoplay === "video=on" ? true : false;
                            }
                            this.setState(newState);
                        } 
                    }catch(err){
                        console.error('err in cis banner',err)
                    }
                }

            }
        }
    }

    showVideo = (url) => {
        this.setState({ isShowVideo: true, showingLink:url });
        try {
            const { trackPageEvent } = window.RETAIL;
            const { region, userType } = utils.getInfoName();
            trackPageEvent(null, 'Video', `Video/${window.location.pathname.split('/')[1]}${region}${userType}`, url)
        } catch (err) {

        }
    }

    hiddenVideo = () => {
        this.setState({ isShowVideo: false,showingLink:'' });
    }

    render() {
        const {  paddingBottom } = this.props
        const { indicesData, isShowVideo, showingLink } = this.state
        if (!indicesData) return null
        const hostName = window.location.origin
        const contentJson = indicesData.content_json;
        const { featureImage, videoLink, video2name, video2Link } = contentJson;
        
        const Background = getImgUrl(hostName, featureImage, null, null, 'cis-public');
        const bannerStyle = {
            paddingBottom: paddingBottom + 'px'
        };

        const hasVideoWithoutImage = videoLink  && !featureImage
        return [
            <div className="cisIndicesBanner" style={bannerStyle} key={indicesData.businessId}>
                <div className={`${isShowVideo ? 'hidden-medias' : ''} cis-medias`} style={hasVideoWithoutImage?{height:'100px'}:null}>
                    {featureImage ? <img src={Background} className="imgContainer" /> : ''}
                    {videoLink &&
                        <img className="playIcon" src='/cis/akpublic/images/play-button.svg' alt="play" onClick={(_) => this.showVideo(videoLink)} style={{
                            left:video2Link?'33%':'50%'
                        }}/>
                    }
                    {video2Link &&
                        <img className="playIcon" src='/cis/akpublic/images/play-button.svg' alt="play" onClick={(_) => this.showVideo(video2Link)} style={{left:'66%'}}/>
                    }

                </div>
                <CisVideo
                    videoLink={showingLink}
                    onClose={this.hiddenVideo}
                    isShowVideo={isShowVideo}
                >
                </CisVideo>
                <div style={hasVideoWithoutImage?null:{height:'50px'}}></div>
            </div>,
            // video2name && video2Link && 
            // <div className='cis-page'>
            //     <a href={video2Link} target='_blank' rel="noopener noreferrer" className='video-link'>{video2name}</a>
            // </div>
        ]
    }
}
export default withRouter(CisIndicesBanner) ;
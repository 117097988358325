import React from 'react'
import HtmlFormatter from '../cisCommon/htmlFormatter'

export default class IndicesPerformance extends React.Component {
    static defaultProps = {
        performance: { columnKeyArray: [], columnTitleArray: [], dataMap: [] },
        combinedReady: false,
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="cis-page-subTitle">Index Performance Statistics</div>
                <table className="cis-indexDetail-table cis-table-blue by-table by-table-striped by-table-around-bordered by-table-hover by-table-l mrg-b15">
                    <thead className="by-header">
                        {this.renderThead()}
					</thead>
                    <tbody className="by-body">
                        {this.renderTbody()}
                    </tbody>
                </table>
            </div>
        )
    }

    renderThead() {
		const { combinedReady } = this.props;
		if(combinedReady){
            if (this.props.performance.columnTitleArray&&this.props.performance.columnTitleArray.length>0) {
                let titleCount = this.props.performance.columnTitleArray.length - 1;
                let trWidth = 288 / (titleCount) + 'px'

                let cutString = titleCount === 1 ? 141 : (titleCount === 2 ? 83 : (titleCount === 3 ? 36 : 20))

                let thSet = this.props.performance.columnTitleArray.map((title, index) => {
                    let updatedTitle = this.replaceAttribute(title||'')
                    let updatedValue = updatedTitle.length > cutString ? (updatedTitle.substr(0, cutString) + '...') : title;
                    return (
                        <th key={index} className="by-col" style={{ width: index === 0 ? "132px" : trWidth }}>
                            <div>
                                <HtmlFormatter value={updatedValue} title={updatedTitle} />
                            </div>
                        </th>
                    )
                })

                return (
                    <tr className="h55">
                        {thSet}
                    </tr>
                )
            }
            else{
                return(
                    <tr>
                        <th className="by-col noData-tr-th">
                        </th>
                    </tr>
                )
            }
        }
        else{
            return(
            <tr className="h55">
                <th className="by-col noData-tr-th">
                </th>
            </tr>)
        }
        

	}
    replaceAttribute(textString) {
        let result = textString.replace('<Super>', "");
        result = result.replace('</Super>', "")
        return result;
    }
    renderTbody() {
        const { combinedReady } = this.props;
        if (combinedReady) {
            if (this.props.performance.columnKeyArray && this.props.performance.columnTitleArray && this.props.performance.dataMap && this.props.performance.columnTitleArray.length > 0) {
                let tempData = []
                this.props.performance.dataMap.map((data) => {
                    let tempRows = []
                    this.props.performance.columnKeyArray.forEach((title) => {
                        if (data.hasOwnProperty(title)) {
                            tempRows.push(data[title])
                        }
                    })
                    tempData.push(tempRows)
                })
                if (tempData && tempData.length > 0) {
                    return (
                        tempData.map((temp, index) => {
                            return (
                                <tr key={index} className="by-row">
                                    {this.renderRows(temp, index)}
                                </tr>
                            )
                        })
                    )
                }
            }
            else {
                return (
                    <tr className="by-row noData-tr">
                        <td>
                            Data Not Available
                        </td>
                    </tr>
                )
            }
        }
    }

    validateDataNegative(validateNum) {
        let isNegative = false;
        const transfferedNum = parseFloat(validateNum);
        if (!isNaN(transfferedNum)) {
            if (transfferedNum < 0 || Object.is(transfferedNum, -0)) {
                isNegative = true;
            }
        }
        return isNegative;
    }
    renderRows(temp, parentIndex) {
        return (
            temp.map((tempRows, index) => {
                return (
                    <td key={index} className="by-col" style={{ color: ((index > 0) ? (this.validateDataNegative(tempRows) ? '#cb6015' : '#174597') : ((tempRows.indexOf('Since') === 0 && tempRows.indexOf('Since Live Date') === -1) ? "#004E89" : "#777")), width: index === 0 ? "132px" : "auto", textAlign: index === 0 ? "left" : "center", fontWeight: (index === 0 && (tempRows.indexOf('Since') === 0 && tempRows.indexOf('Since Live Date') === -1)) ? "600" : "500" }}>
                        <HtmlFormatter value={tempRows} />
                    </td>
                )
            })
        )
    }
}

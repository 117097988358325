import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const trackPageEvent = window.RETAIL.trackPageEvent


export default function ({ menuNode, pattern, className, style, target, children, state , tabIndex}) {
    let link = `/${menuNode === "regional-sites"?'our-approach/structured-products':menuNode}`;
    if (pattern) link += `/${pattern}`;

    //add menuNode for cis, business rule is menuNode === 'CrossAssetSolution', then navigate to retail home

    if(menuNode === 'CrossAssetSolution') {
        link = 'https://www.crossassetsolutions.citi.com'

        return (
            <a 
                href={link}
                target='_blank'
                onClick={() => trackPageEvent(clickPath, 'Navigation', `NavigationTo${link}`, `NavigationFrom${window.location.pathname || "HomePage"}`)}
                className={className}
                style={style}
            >
            {children}
            </a>
        )
    }
    const clickPath = link.slice(1);
    return (
            <Link
                to={state ? { pathname: link, state } : link}
                className={className}
                style={style}
                target={target}
                tabIndex={tabIndex}
                onClick={() => trackPageEvent(clickPath, 'Navigation', `NavigationTo${link}`, `NavigationFrom${window.location.pathname || "HomePage"}`)}
            >
                {children}
            </Link>
    )
}

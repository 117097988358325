import React from 'react'
import Contact from './contact'
import Disclaimer from './disclaimer'
import Dropdown from '../cisCommon/dropdown'
import SvgGenerator from '../svgGenerator/svgGenerator'
import {getQueryUrl} from '../../utils/URLUtils'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            regionName: '',
            userTypeName: '',
            detailPathname: null,
            disableLike: false,
            emailAutoPlayVideo: ''
        }
    }
    static defaultProps = {
        region: 0,
        usertype: 0,
        regionList: [],
        usertypeList: [],
        regionSelected: false,
        usertypeSelected: false
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.regionList.length !== prevProps.regionList.length) {
            if (this.state.detailPathname && this.state.detailPathname[2] === 'detail') {
                const regionId = Number(this.state.detailPathname[4])
                const useType = Number(this.state.detailPathname[5])
                this.getDetailUserTypeRegionName(regionId, useType)
                localStorage.setItem('userInfo', JSON.stringify({
                    region: regionId,
                    userType: useType
                }))
                this.refs.disclaimer.getDisclaimer(regionId, regionId)

            }
            if(window.location.href.includes('?')){
                const regionId = Number(getQueryUrl('regionId'));
                this.regionTypeChange(regionId, 2);
            }
        }

    }


    componentDidMount(){
        const detailPathname = sessionStorage.getItem('detailIndicePathname') && sessionStorage.getItem('detailIndicePathname').split('/')
        const emailAutoPlayVideo = sessionStorage.getItem('emailAutoPlayVideo') && sessionStorage.getItem('emailAutoPlayVideo')
        this.setState({
            detailPathname: detailPathname,
            emailAutoPlayVideo: emailAutoPlayVideo,
            disableLike: sessionStorage.getItem('isEmailDetail') === 'true',
        })
        sessionStorage.setItem('isEmailDetail', "false");
        if(window.location.href.includes('?')){
            const regionId = Number(getQueryUrl('regionId'));
            this.getDetailUserTypeRegionName(regionId, 2);
            this.regionTypeChange(regionId, 2);
            this.refs.disclaimer.getDisclaimer(regionId, 2);
        } 
    }

    componentWillMount() {
        const { actions } = this.props;
        actions.userTypeChange(0);
        actions.regionTypeChange(0);
        actions.fetchUsertypeRegion();
        actions.clearUsertypeRegion();
    };

    getDetailUserTypeRegionName = (regionId, useType) => {
        const { regionList, usertypeList } = this.props
        let regionName = "";
        let userTypeName = "";
        regionList.map((regionItem) => {
            if (regionItem.id === regionId) {
                regionName = regionItem.label;
            }
        })
        usertypeList.map((userItem) => {
            if (userItem.id === useType) {
                userTypeName = userItem.label
            }
        })
        this.setState({
            regionName: regionName,
            userTypeName: userTypeName
        })
    }

    regionTypeChange = (value, useType) => {
        const { actions, usertype, regionList, usertypeList, profileRelation } = this.props
        actions.regionTypeChange(value)
        const newUserType = useType ? 2 : usertype;
        if ((value > 0) && (newUserType > 0)) {
            if (this.verifyIfDropdownBeRest(value, newUserType, profileRelation) && !useType) {
                // actions.userTypeChange(0) mock
                this.userTypesDropdown && this.userTypesDropdown.resetWithWindowOpen();
            }
            else {
                let regionName = "";
                let userTypeName = "";
                regionList.map((regionItem) => {
                    if (regionItem.id === value) {
                        regionName = regionItem.label;
                    }
                })
                usertypeList.map((userItem) => {
                    if (userItem.id === newUserType) {
                        userTypeName = userItem.label
                    }
                })
                localStorage.setItem('userTypeRegionName', JSON.stringify({
                    region: regionName,
                    userType: userTypeName
                }))
                this.refs.disclaimer.getDisclaimer(value === 1 ? 1 : 2, newUserType)
            }
        }
    }

    userTypeChange(value) {
        const { actions, region, regionList, usertypeList, profileRelation } = this.props
        actions.userTypeChange(value)
        if ((value > 0) && (region > 0)) {
            if (this.verifyIfDropdownBeRest(region, value, profileRelation)) {
                actions.regionTypeChange(0);
                this.regionsDropdown.resetWithWindowOpen();
            }
            else {
                let regionName = "";
                let userTypeName = "";
                regionList.map((regionItem) => {
                    if (regionItem.id === region) {
                        regionName = regionItem.label;
                    }
                })
                usertypeList.map((userItem) => {
                    if (userItem.id === value) {
                        userTypeName = userItem.label
                    }
                })
                localStorage.setItem('userTypeRegionName', JSON.stringify({ region: regionName, userType: userTypeName }))
                this.refs.disclaimer.getDisclaimer(region === 1 ? 1 : 2, value)
            }
        }
    }

    verifyIfDropdownBeRest(regionSelected, usertypeSelected, profileRelation) {
        return !profileRelation.some(profile => Number(profile.regionId) === regionSelected && Number(profile.investorTypeId) === usertypeSelected);
    }

    filterRegionAndInvestType(regionList, usertypeList, regionSelected, usertypeSelected, region, usertype, profileRelation) {
        let regions = regionList.filter(region => profileRelation.some(profile => Number(profile.regionId) === region.id));
        let userTypes = usertypeList.filter(user => profileRelation.some(profile => Number(profile.investorTypeId) === user.id));
        if (regionSelected && !usertypeSelected) {
            if (profileRelation) {
                let tempUserType = []
                for (let i = 0; i < profileRelation.length; i++) {
                    if (Number(profileRelation[i].regionId) === region) {
                        tempUserType.push(Number(profileRelation[i].investorTypeId));
                    }
                }
                userTypes = usertypeList.filter(user => (tempUserType.some(temp => temp === user.id)))
            }
        }
        else if (!regionSelected && usertypeSelected) {
            if (profileRelation) {
                let tempRegions = []
                for (let i = 0; i < profileRelation.length; i++) {
                    if (Number(profileRelation[i].investorTypeId) === usertype) {
                        tempRegions.push(Number(profileRelation[i].regionId));
                    }
                }
                regions = regionList.filter(user => (tempRegions.some(temp => temp === user.id)))
            }
        }
        return {
            regions: regions,
            userTypes: userTypes,
        }
    }

    renderdetailCisRegionList = () => {
        return <div className="login-detail-indice">
                    <span className="login-detail-indice-region">{this.state.regionName}</span>
                    <SvgGenerator type='by-icon:chevron-thin-s' svgSize='8' style={{ position: 'absolute', top: '6px', color: '#95aabc',right: '45%',cursor: "not-allowed" }}></SvgGenerator>
                </div>
    }

    getInitRegion = (regionId, regionList) => {
       if(regionList.length > 0 && regionList.find(item => item.id === regionId)) {
          return regionList.find(item => item.id === regionId).label ? regionList.find(item => item.id === regionId).label : '';
       }
    }

    render() {
        const { actions, regionList, usertypeList, regionSelected, usertypeSelected, region, usertype, profileRelation } = this.props;
        const { disableLike, emailAutoPlayVideo, detailPathname } = this.state;
        const { regions, userTypes } = this.filterRegionAndInvestType(regionList, usertypeList, regionSelected, usertypeSelected, region, usertype, profileRelation);
        const isEmailDetail = sessionStorage.getItem('isEmailDetail')
        const regionSelectedInit = this.getInitRegion(region, regions) || 'SELECT YOUR PROFILE';
        const defaultLabelValue = window.location.href.includes('?') ? regionSelectedInit : 'SELECT YOUR PROFILE';
        const mqaTicker = getQueryUrl('mqaTicker');

        return (
            <div className="cis-login">
                <div className="margin-bottom-15"><img width="100%" src="/cis/akpublic/images/login-banner.png" /></div>
                <div className="cis-region-usertype-container">
                    <h2>Select your Region and User Type</h2>
                    {
                        detailPathname && disableLike && detailPathname[2] === 'detail'
                            ? this.renderdetailCisRegionList()
                            : regions.length >0 ? <Dropdown list={regions} defaultLabel={defaultLabelValue} ref={(c) => {
                                this.regionsDropdown = c
                            }}  
                                onChange={this.regionTypeChange.bind(this)}></Dropdown>: null
                    }
                </div>
                <div className="cis-select-disclaimer">
                    {
                        detailPathname && disableLike &&detailPathname[2] === 'detail'
                            ? <Disclaimer show={true} ref="disclaimer" detailPathname={detailPathname} region={Number(detailPathname[4])}
                                userType={Number(detailPathname[5])} profileRelation={profileRelation} emailAutoPlayVideo={emailAutoPlayVideo}></Disclaimer>
                            : <Disclaimer show={regionSelected && usertypeSelected} ref="disclaimer" region={region}
                                userType={usertype} profileRelation={profileRelation} mqaTicker={mqaTicker}></Disclaimer>
                    }
                </div>
                {/* <Contact></Contact> */}
            </div>
        )
    };
}


export default Login
import React from 'react'
import HtmlFormatter from './../cisCommon/htmlFormatter';

export default class IndicesDisclaimer extends React.Component{
	static defaultProps = {
		disclaimer:"",
		footNote:"",
		isDisclaimer:false,
		style: {},
	};
	
	constructor(props){
		super(props);
	}
	
	 render(){
		 
		const {disclaimer,footNote,isDisclaimer, style} =this.props 
		if(isDisclaimer){
		    return (
		        <div className="w100p">	        	
	        		<div className="fl"><p className="mrg5-0" style={style}><HtmlFormatter value={disclaimer}/></p></div>	        	   	                     
		        </div>
		    )
	    }
		else{
			 return (
		        <div className="w100p">	        	
	        		<div  className="fl"><p className="mrg5-0" style={style}><HtmlFormatter value={footNote}/></p></div>	        	                     
		        </div>
		    )
		}
	  };  
}



class RetailUtils {

    static parseConfig(config) {
        if (!!config) {
            if (typeof config === 'string')
                return JSON.parse(config);
            else if (typeof config === 'object') {
                return config;
            }
        }
        return {};
    }

    static getCookieByName(cname) {
        let ccookie = document.cookie.split(';').filter((item) => item.includes(`${cname}=`));
        if (ccookie.length) {
            return ccookie[0].trim().substring(cname.length + 1);  //remove the 'cname=' part
        }
        return '';
    }

    static setPreviewParams(ctx) {
        const searchStr = ctx.props.location.search;
        const index = searchStr.indexOf('preview_nonce') >=0 ? searchStr.lastIndexOf('=') + 1 : '';
        ctx.previewNonce = index ? searchStr.substring(index, searchStr.length) : '';
    }

    // get video id from src
    static getIdFromVideoSrc = (src) => {
        const srcArr = src.split('/');
        const videoId = srcArr[srcArr.indexOf('view')+1];
        return videoId;
    }
}

export default RetailUtils;
import React, { useState, useEffect,useCallback } from 'react'
import Page from '../../components/commons/page/Page';
import { citiRequest } from '../../utils/Request';
import { withRouter } from 'react-router-dom';
import utils from '../../components/cisCommon/utils';

const { env_config:{siteId} } = window


const ConfiguredPage = (props) => {
    const searchStr = props.location.search;
    const index = searchStr.lastIndexOf('=') + 1;
    const previewNonce = index ? searchStr.substring(index, searchStr.length) : '';
    const [page, setPage] = useState(null)
    const [pageName] = useState(props.pageName)
    const [pageId] = useState(props.match.params.pageId)

    console.log(props)

    const initData = useCallback(async ()=>{
        let url = `/rcs/v1/page/${siteId}/pageName/${pageName}`;
        if (pageId&&!pageName){
             url = previewNonce ? `/rcs/v1/page/${siteId}/preview/${pageId}?preview=true&preview_nonce=${previewNonce}` : `/rcs/v1/page/${siteId}/uiPage/${pageId}`
        }
        const pageRsp = await citiRequest.get(url)
        if(pageRsp.success === 1){

            if(pageRsp.data &&JSON.stringify(pageRsp.data)!=='{}'){
                setPage(pageRsp.data)
            } else {
                props.history.replace('/error-page')
            }
            
        }
    },[pageName,pageId])

    useEffect(() => {
        initData()
        if(props.checkUrl){
           const {regionId,investorTypeId} = props.match.params
           utils.verifyRegionAndInvestorType(regionId,investorTypeId)
        }

    },[pageName,props.checkUrl])

    if (!page) return null

    return (
        <div className="PageContainer">
            <Page page={page.uiRows}></Page>
        </div>
    )
}

export default withRouter(ConfiguredPage)

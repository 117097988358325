const { env_config:{siteId} } = window

export  const getImgUrl = (hostName,id,width,height) => {
    if (width&&height) {
        return `${hostName}/rcs/v1/media/${siteId}/akpublic/storage/retrieveMediaFile/${id}/${width}x${height}.jpg`
    } else {
        return `${hostName}/rcs/v1/media/${siteId}/akpublic/storage/retrieveMediaFile/${id}.jpg`
    }
}


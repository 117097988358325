import * as ActionTypes from '../../actions/ActionTypes';

const initialState = {
    windowSize: window.innerWidth,
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.CHANGE_WINDOW_SIZE:
        return {
          ...state,
          windowSize: action.width,
        };
      default:
        return state;
    }
}

export const getWindowSize = state => state.eventReducer.windowSize

export default eventReducer;
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import $ from 'jquery';

export default class IndicesChartingCalendar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: moment(),
			endDate: moment(),
			maxEndDate: moment(),
			minStartDate: moment(),
			startCalendarShow: false,
			endCalendarShow: false
		};
	}
	initMinDateAndMaxDate(startDate,endDate){
		this.setState({
			maxEndDate: moment(Number(endDate)),
			minStartDate: moment(Number(startDate)),
			startDate: moment(Number(startDate)),
			endDate: moment(Number(endDate)),
		});
	}

	updateDatePicker(value) {
		this.setState({
			startDate: moment(Number(value)),
		});
	}

	updateEndPicker(value) {
		this.setState({
			endDate: moment(Number(value)),
		});
	}

	handleClickOutside() {
		this.setState({
			startCalendarShow: false,
			endCalendarShow: false
		});
	}

	transferDate(dateString) {
		let result = 'NaN';
		if (dateString && dateString.length > 0) {
			let dateArray = dateString.split('-');
			if (dateArray && dateArray.length === 3) {
				let date = dateArray[0];
				let month = dateArray[1];
				let year = Number(dateArray[2]);
				if (year && year <= 99 && year >= 18) {
					year = `19${dateArray[2]}`;
				}
				else {
					year = `20${dateArray[2]}`;
				}
				let dateUpdatedString = `${month} ${date} ${year}`;
				result = Date.parse(dateUpdatedString);
			}
		}
		return result;
	}

	startDateChange(date) {
		this.props.onChange(Date.parse(date));
		this.setState({
			startDate: moment(Date.parse(date)),
			startCalendarShow: false,
			endCalendarShow: false
		});
	}

	endDateChange(date) {
		this.props.onChange(Date.parse(date));
		this.setState({
			endDate: moment(Date.parse(date)),
			startCalendarShow: false,
			endCalendarShow: false
		});
	}

	componentDidUpdate(){
		const { startCalendarShow, endCalendarShow } = this.state;
		if (startCalendarShow || endCalendarShow) {
			$('div.react-datepicker__year-read-view').css('width', '63.8594px');
			$('span.react-datepicker__year-read-view--down-arrow').css('top','8px');
		}
	}

	showStartCalendar(){
		this.setState({
			startCalendarShow: true,
		});
	}

	showEndCalendar(){
		this.setState({
			endCalendarShow: true,
		});
	}

	hideStartCalendar(){
		this.setState({
			startCalendarShow: false,
		});
	}

	hideEndCalendar(){
		this.setState({
			endCalendarShow: false,
		});
	}

	updateStartCalendar(temp_utc){
		this.setState({
			startDate: moment(temp_utc),
		});
	}

	reSetStartCalendar(){
		const {minStartDate} = this.state;
		this.setState({
			startDate: minStartDate,
		});
	}

	updateEndCalendar(temp_utc){
		this.setState({
			endDate: moment(temp_utc),
		});
	}

	reSetEndCalendar(){
		const {maxEndDate} = this.state;
		this.setState({
			endDate: maxEndDate,
		});
	}

	renderCalendar() {
		let {minStartDate, maxEndDate, startCalendarShow, endCalendarShow} = this.state;
		if (this.props.isStartDate && startCalendarShow) {
			return (
				<DatePicker 
					showYearDropdown 
					inline 
					minDate={minStartDate.toDate()} 
					maxDate={maxEndDate.toDate()} 
					selected={this.state.startDate.toDate()} 
					onChange={this.startDateChange.bind(this)} 
					onClickOutside={this.handleClickOutside.bind(this)} 
				/>
			);
		}
		else if(endCalendarShow){
			return (
				<DatePicker 
					showYearDropdown 
					inline 
					minDate={minStartDate.toDate()} 
					maxDate={maxEndDate.toDate()} 
					selected={this.state.endDate.toDate()} 
					onChange={this.endDateChange.bind(this)}
					onClickOutside={this.handleClickOutside.bind(this)} 
				 />
			);
		}else{
			return <div />;
		}
	}
	render() {
		return (
			<div style={{ position: 'absolute', top: '60px', left: this.props.left, zIndex: '130'}}>
				{this.renderCalendar()}
			</div>
		);
	}
}

IndicesChartingCalendar.defaultProps = {
	onChange:function(){},
	isStartDate:true,
	left:590,
};

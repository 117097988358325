import React from 'react'

export default class LoadingMask extends React.Component{
  static defaultProps = {
    show:true
  }; 


 
  render(){
    return (
      <div className="cis-loading" data-show={this.props.show}>
          <div className="cis-loading-bg"></div>
          <div className="by-loading by-loading-l">
            <span className="by-loading-text">Loading...</span>
            <div className="by-loading-icon by-animated by-infinite by-rotate"></div>
          </div>
        </div>
    )
  };
}
/**
 * Current Hard code Menus
 * Finally we will configure menu in CMS
 */

 export const MENU_CONFIG = {
    // "CIS_HOME":{
    //     path:'/cis-home',
    //     pageName:"CIS_HOME_PAGE",
    //     displayName:"Home",
    //     showInMenu:true
    // },
     "CIS_HOME_PAGE":{
         path:'/cis-home/:regionId/:investorTypeId',
         pageName:"CIS_HOME_PAGE",
         displayName:"Home",
         showInMenu:true,
         checkUrl:true
     },
    //  "CIS_INDICES":{
    //     path:'/indices',
    //     pageName:"CIS_INDICES",
    //     displayName:"Indices",
    //     showInMenu:true,
    //     checkUrl:true
    // },
    "CIS_GOVERNANCE":{
        path:'/cis-governance/:regionId/:investorTypeId',
        pageName:"CIS_GOVERNANCE",
        displayName:"Governance",
        showInMenu:true
    },
    "CIS_PRODUCTS":{
        path:'/our-products/:pageName',
        pageName:"CIS_PRODUCTS",
        displayName:""
    },
    "CIS_TERM_CONDITION":{
        path:'/cis-terms-conditions',
        pageName:"CIS-Terms",
    },
    "CIS_PRIVACY":{
        path:'/cis-privacy',
        pageName:"CIS-Privacy",
    },
    "CIS_DISCLAIMER":{
        path:'/cis-disclaimer',
        pageName:"CIS-Disclaimer",
    },
    "CIS-ABOUT":{
        path:'/cis-about/:regionId/:investorTypeId',
        pageName:'CIS_About'
    },
    // "CIS-CONTACT_US":{
    //     path:'/contact',
    //     pageName:'Contact_Us'
    // },
    "CIS-HELP":{
        path:'/cis-help',
        pageName:'Help'
    },
 }
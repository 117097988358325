import React from 'react';
import $ from 'jquery';
import SvgGenerator from '../svgGenerator/svgGenerator'

export default class Dropdown extends React.Component {
    static defaultProps = {
        list: [],
        defaultLabel: "",
        onChange: function () {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selected: props.selected || (props.list ? props.list[0] : []),
            label: props.defaultLabel || (props.selected ? props.selected.label : '') || (props.list ? props.list[0].label : '')
        };
    }

    select(item) {
        if (this.state.label != item.label) {
            this.setState({
                label: item.label,
                show: false
            });
            this.props.onChange(item.id);
        }
    }

    resetWithWindowOpen() {
        const { defaultLabel } = this.props;
        this.setState({
            label: defaultLabel || '',
        })
    }

    toggleOpen() {
        let state = this.state;
        state.show = !state.show;
        this.setState({ show: state.show });
    }

    render() {
        let maskWidth = document.body.clientWidth;
        let maskHeight = document.body.clientHeight;
        $('.cis-dropdown .mask').css({ width: maskWidth + 'px', height: maskHeight + 'px' });
        return (
            <div className="cis-dropdown">
                <div className="mask" data-show={this.state.show} onClick={() => this.toggleOpen()}></div>
                <div
                    className={this.state.show ? 'by-dropmenu by-dropmenu-selected by-fixed-w200' : 'by-dropmenu by-fixed-w200'}
                    onClick={() => this.toggleOpen()}>
                    <div className="by-dropmenu-toggle">
                        <div className="by-dropmenu-label-variable">{this.state.label}</div>
                        {this.state.show
                            ? <SvgGenerator type='by-icon:chevron-thin-n' svgSize='8' style={{ position: 'absolute', top: '6px', color: '#95aabc' }}></SvgGenerator>
                            : <SvgGenerator type='by-icon:chevron-thin-s' svgSize='8' style={{ position: 'absolute', top: '6px', color: '#95aabc' }}></SvgGenerator>}
                    </div>
                    {this.renderListItems()}
                </div>
            </div>
        );
    }

    renderListItems() {
        let items;
        if (this.props.list) {
            items = this.props.list.map((item) => {
                let itemKey = item.id + item.label;
                return <li className={(this.state.label == item.label) ? 'by-item by-menuoption-selected' : 'by-item'}
                    key={itemKey} value={item.label} onClick={() => this.select(item)}>
                    <div className="by-menuoption-marker">
                        <span className="dotmarker"></span>
                    </div>
                    <div className="by-menuoption-label">{item.label}</div>
                </li>;
            });
        }
        return (
            <div className="by-dropmenu-options-wrapper by-fixed-width">
                <ul className="by-dropmenu-options">
                    {items}
                </ul>
            </div>
        );
    }
}
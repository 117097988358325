import React from 'react'
import HtmlFormatter from '../cisCommon/htmlFormatter'
import moment from 'moment'

export default class IndicesDetailStandardised extends React.Component {
    static defaultProps = {
        standardised: {columnKeyArray: [], columnTitleArray: [], dataMap: []},
        combinedReady: false,
    };


    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div>
                    <div className='cis-page-title'>Standardized Past Performance Information</div>
                    <table
                        className="cis-table-blue by-table by-table-striped by-table-around-bordered by-table-hover by-table-l cis-indices-standarised">
                        <thead className="by-header">
                        <tr>
                            {this.renderThead()}
                        </tr>
                        </thead>
                        <tbody className="by-body">
                        {this.renderTbody()}
                        </tbody>
                    </table>
                    <div className='cis-indicesDetail-endText'>Rolling 12 month returns above display the calendar days
                        (rather than business days) of each respective 1 year period.
                    </div>
                </div>
            </div>
        )
    }

    renderThead() {
        const {combinedReady,liveDate} = this.props;
        if (combinedReady) {
            if (this.props.standardised.columnTitleArray && this.props.standardised.columnTitleArray.length > 0) {
                const original = [...this.props.standardised.columnTitleArray]
                return (
                    original.reverse().map((title, index) => {
                        console.log(title,liveDate)
                        let [start,end] = title.split('to')
                        let category = ''
                        const DATE_FORMAT = 'DD-MMM-YY'
                        let startMoment = moment(start,DATE_FORMAT)
                        let endMoment = moment(end,DATE_FORMAT)
                        let liveMoment = moment(liveDate,DATE_FORMAT)
                        const isNotLived = liveDate === '01-Jan-01'
                        switch(true){
                            case isNotLived || liveMoment.isSame(endMoment)||liveMoment.isAfter(endMoment):
                            category = 'Simulated'
                            break;
                            case !isNotLived&&(liveMoment.isSame(startMoment)||liveMoment.isBefore(startMoment)):
                            category ='Live'
                            break;
                            default: 
                            category = 'Blended'
                            break
                        }
                        return (
                            <th key={index} className={`by-col ${category}`}>
                                <HtmlFormatter value={title}/><br/>
                                <span>({category})</span>
                            </th>
                        )
                    })
                )
            }
            else {
                return (
                    <th className="by-col noData-tr-th">
                    </th>
                )
            }
        }
    }

    renderTbody() {
        const {combinedReady} = this.props;
        if (combinedReady) {
            if (this.props.standardised.columnKeyArray && this.props.standardised.columnTitleArray && this.props.standardised.dataMap && this.props.standardised.dataMap.length>0) {
                let tempData = []
                this.props.standardised.dataMap.map((data) => {
                    let tempRows = []
                    const original = [...this.props.standardised.columnKeyArray]
                    original.reverse().forEach((title) => {
                        if (data.hasOwnProperty(title)) {
                            tempRows.push(data[title])
                        }
                    })
                    tempData.push(tempRows)
                })
                if (tempData && tempData.length > 0) {
                    return (
                        tempData.map((temp, index) => {
                            return (
                                <tr key={index} className="by-row">
                                    {this.renderRows(temp, index)}
                                </tr>
                            )
                        })
                    )
                }
            }
            else {
                return (
                    <tr className="by-row noData-tr">
                        <td>
                            Data Not Available
                        </td>
                    </tr>
                )
            }
        }
    }

    renderRows(temp, parentIndex) {
        return (
            temp.map((tempRows, index) => {
                return (
                    <td key={index} className="by-col">
                        <HtmlFormatter value={tempRows}/></td>
                )
            })
        )
    }
}

import React, { Component } from 'react';

import portletList from '../../../utils/PortletList';
import './Portlet.css';
import { connect } from 'react-redux';
import * as eventReducer from '../../../reducers/event/eventReducer';
import RetailUtils from '../../../utils/RetailUtils';
import { getRcsUrl } from '../../../utils/Request';

const { env_config:{siteId} } = window


class Portlet extends Component {

    renderPortlet(portletType) {
        return
    }

    render() {
        const { onDelete, portletType, config } = this.props;
        if (portletType === 'Rows') return null;
        const Component = portletList[portletType.split(' ').join('')];
        if(Component === undefined){
            console.log(`Porltet ${portletType} is undefined`)
        }
        const configObject = RetailUtils.parseConfig(config);
        return (
            <Component
                onDelete={onDelete}
                config={config}
                {...configObject}
                hostName={getRcsUrl()}
                windowSize={this.props.windowSize}
                siteId={siteId}
            />
        );
    }
}

const mapStateToProps = state => ({
    windowSize:eventReducer.getWindowSize(state)
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Portlet);
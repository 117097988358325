import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './GridRow.css'

export default class GridRow extends Component {

    static propsTypes = {
        isFullScreen: PropTypes.bool,
    }

    static defaultProps = {
        isFullScreen: false,
    }

    render() {
        const { alignment, children } = this.props;
        let container = '';
        switch (alignment){
            case 'full'  : container = 'container-fluid';
            break;
            case 'center': container = 'container container-custom';
            break;
            default : container = 'container narrow container-custom';
            // break;
        }

        return (
            <div className="GridRow">
                <div className={container}>
                    <div className="row">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}
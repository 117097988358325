import React from 'react'
import Utils from '../cisGrid/utils'

class Contact extends React.Component{
  static defaultProps = {
    contactText: ''
  };


  componentDidMount(){
    const {regionId,investorTypeId} = Utils.getUserInfo()
    const { actions } = this.props
    //actions.fetchContactText({regionId,investorTypeId});
  };

  render(){
      const contactPreText = "Financial Intermediaries and Wealth Management Professionals can contact us and learn more about Citi’s range of products and services by visiting the "
      const contactText = "."

    //const { contactText } = this.props
    return (
		<div className="cis-contactUs-area">
            <div className="cis-contactUs-title">Contact Us</div>
            <div className="tj lh18 cis-contactUs-content"><span>{contactPreText}</span><a href="https://www.crossassetsolutions.citi.com">Cross Asset Solutions website</a><span>{contactText}</span></div>

        </div>
    );
  };
}



export default Contact;
import React from 'react';
import ReactHighstock from 'react-highcharts/ReactHighstock';
import $ from 'jquery';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from './indicesChartingCalendar';
import PropTypes from 'prop-types';
import './indiceDetail.css';
import SvgGenerator from '../svgGenerator/svgGenerator';
import * as d3 from 'd3-selection';

export default class IndicesCharting extends React.Component {
	shouldComponentUpdate() {
		return false;
	}

	componentWillUnmount() {
		this.clearChart();
	}

	componentDidMount() {
		ReactHighstock.Highcharts.wrap(ReactHighstock.Highcharts.Axis.prototype, 'getPlotLinePath', function (proceed) {
			var path = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
			if (path) {
				path.flat = false;
			}
			return path;
		});
		ReactHighstock.Highcharts.setOptions({
			lang: {
				rangeSelectorFrom: 'FROM    .',
				rangeSelectorTo: '.  TO    .'
			},
		});
		this.initCalendarFromAndToPosition();
	}

	clearChart() {
		let chart = this.chart.getChart();
		for (let i = chart.series.length - 1; i > 0; i--) {
			chart.series[0].remove();
		}
		for (let i = chart.xAxis[0].plotLinesAndBands.length; i > 0; i--) {
			chart.xAxis[0].removePlotLine(chart.xAxis[0].plotLinesAndBands[i]);
		}
	}

	startDateChange = (date) => {
		let chart = this.chart.getChart();
		const maxDate = chart.xAxis[0].getExtremes().max;
		if (maxDate > date) {
			chart.xAxis[0].setExtremes(date, maxDate, true, false);
		}
	}


	endDateChange = (date) => {
		let chart = this.chart.getChart();
		const minDate = chart.xAxis[0].getExtremes().min;
		if (minDate < date) {
			chart.xAxis[0].setExtremes(minDate, date, true, false);
		}
	}

	componentWillReceiveProps(nextProps) {
		$('input.highcharts-range-selector:eq(0)').attr('tabindex', '-1');
		$('input.highcharts-range-selector:eq(1)').attr('tabindex', '-1');
		if ((this.props.data &&
			this.props.data.length === 0) ||
			(
				nextProps.data &&
				nextProps.data[0] &&
				nextProps.data[0].chartData &&
				this.props.data &&
				this.props.data[0] &&
				this.props.data[0].chartData &&
				nextProps.data[0].chartData !== this.props.data[0].chartData
			)) {

			if (nextProps.data && nextProps.data[0] && nextProps.data[0].chartData) {
				let startDate = nextProps.data[0].chartData.split(',')[0].substring(2, nextProps.data[0].chartData.split(',')[0].length);
				let endDate = nextProps.data[0].chartData.split(',')[nextProps.data[0].chartData.split(',').length - 2].substring(2, nextProps.data[0].chartData.split(',')[nextProps.data[0].chartData.split(',').length - 2].length);

				this.st.initMinDateAndMaxDate(startDate, endDate);
				this.et.initMinDateAndMaxDate(startDate, endDate);
			}
			this.clearChart();
			let chart = this.chart.getChart();
			let chartData = [];
			let lineData = [];
			let createdBenchMarkCount = 0;
			let chartLength = 0;
			if (nextProps.data.length > 0) {
				let baseData = nextProps.data;
				let isRebased = baseData[0].isRebased;
				let serverTimeZone = baseData[0].serverTimeZone;
				ReactHighstock.Highcharts.setOptions({
					global: {
						useUTC: true,
						timezoneOffset: -serverTimeZone * 60
					}
				});
				for (let i = 0; i < baseData.length; i++) {
					let color = '';
					if (baseData[i].hasOwnProperty('chartLength')) {
						color = 'rgba(0, 119, 204,0.5)';
						chartLength = baseData[i].chartLength;
					}
					else {
						if (createdBenchMarkCount === 0) {
							color = '#C6D6E8';
						}
						else if (createdBenchMarkCount === 1) {
							color = '#1FBECA';
						}
						else if (createdBenchMarkCount === 2) {
							color = '#cb6015';
						}
						createdBenchMarkCount++;
					}

					let pointFormatString = ('<span style="color:{series.color}">●</span> {series.name}: <b>{point.y}</b><br/>')
						+ ((i === baseData.length - 1 && isRebased) ? ('<span>Index levels have been rebased</span>') : '');


					let chart = {
						name: baseData[i].name,
						data: JSON.parse(baseData[i].chartData),
						tooltip: {
							headerFormat: '<span style=font-size: 10px>{point.key}</span><br/>',
							pointFormat: pointFormatString,
							valueDecimals: 2
						},
						color: color,
						threshold: null,
						dataGrouping: {
							enabled: false
						},
						type: i === 0 ? 'area' : 'line',
						fillColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1
							},
							stops: [
								[0, 'rgba(0, 189, 242, 0.5)'],
								[1, 'rgba(255, 255, 255, 0.5)']
							]
						},
						fillOpacity: 0.5
					};
					chartData.push(chart);
				}
				let liveDate = baseData[0].liveDate;
				let fundProxyDate = baseData[0].fundProxyDate;

				if (liveDate && liveDate !== -10000000000000) {
					let liveLine = {
						label: {
							text: 'Live Date', rotation: 90, style: {
								color: '#cb6015',
							}
						},
						color: '#cb6015',
						width: 2,
						value: liveDate,
						zIndex: 5,
					};
					lineData.push(liveLine);
				}
				if (liveDate && fundProxyDate !== -10000000000000) {
					let fundLine = {
						label: {
							text: 'Proxy End Date', rotation: 90, y: 90, style: {
								color: '#c99700',
							}
						},
						color: '#c99700',
						width: 2,
						value: fundProxyDate,
						zIndex: 5,
					};
					lineData.push(fundLine);
				}
				let textContent = isRebased ? 'Performance (rebased)' : 'Performance';
				chart.yAxis[0].setTitle({
					text: textContent,
					align: 'middle',
				});
			}
			else {
				this.clearChart();
				chart.yAxis[0].setTitle({
					text: '',
					align: 'middle',
				});
			}

			if (chartData.length > 0) {
				$('g.highcharts-range-input:eq(0)').unbind();
				$('g.highcharts-range-input:eq(1)').unbind();
				$('input.highcharts-range-selector:eq(0)').unbind();
				$('input.highcharts-range-selector:eq(1)').unbind();
				$('g.highcharts-range-input:eq(0)').mouseup(this.initialStartCalendar.bind(this));
				$('g.highcharts-range-input:eq(1)').mouseup(this.initialEndCalendar.bind(this));
				for (let i = 0; i < chartData.length; i++) {
					chart.addSeries(chartData[i]);
				}
				for (let i = 0; i < lineData.length; i++) {
					chart.xAxis[0].addPlotLine(lineData[i]);
				}
			} else {
				$('g.highcharts-range-input:eq(0)').unbind();
				$('g.highcharts-range-input:eq(1)').unbind();
				$('input.highcharts-range-selector:eq(0)').unbind();
				$('input.highcharts-range-selector:eq(1)').unbind();
				$('input.highcharts-range-selector:eq(0)').focus(function () {
					$(this).blur();
				});
				$('input.highcharts-range-selector:eq(1)').focus(function () {
					$(this).blur();
				});
			}
			let resizeHeight = 72 + chartLength * 26;
			chart.setSize(this.props.graph_width || 720, resizeHeight, true);
			chart.redraw();
			$.each(chart.rangeSelector.buttons, function (i, button) {
				let textStr = button.element.childNodes[1];
				$(textStr).attr({
					y: 14
				});
			});
			this.initCalendarFromAndToPosition();
		}
	}

	initialStartCalendar() {
		if (this.st) {
			let value = $('input.highcharts-range-selector:eq(0)').val();
			let temp_date = new Date(value);
			let temp_utc = temp_date.valueOf();
			if (temp_utc > 0 && !isNaN(temp_utc)) {
				this.st.updateStartCalendar(temp_utc);
			} else {
				this.st.reSetStartCalendar();
			}
		}
		this.st.showStartCalendar();
	}

	initialEndCalendar() {
		if (this.et) {
			let value = $('input.highcharts-range-selector:eq(1)').val();
			let temp_date = new Date(value);
			let temp_utc = temp_date.valueOf();
			if (temp_utc > 0 && !isNaN(temp_utc)) {
				this.et.updateEndCalendar(temp_utc);
			} else {
				this.et.reSetEndCalendar();
			}
		}
		this.et.showEndCalendar();
	}

	updateCalendar(event) {
		if (event.target.className === 'highcharts-range-selector') {
			if (event.target.name === 'min') {
				this.st.showStartCalendar();
				if (this.st) {
					let value = $('input.highcharts-range-selector:eq(0)').val();
					let temp_date = new Date(value);
					let temp_utc = temp_date.valueOf();
					if (temp_utc > 0 && !isNaN(temp_utc)) {
						this.st.updateStartCalendar(temp_utc);
					} else {
						this.st.reSetStartCalendar();
					}
				}
			} else if (event.target.name === 'max') {
				this.et.showEndCalendar();
				if (this.et) {
					let value = $('input.highcharts-range-selector:eq(1)').val();
					let temp_date = new Date(value);
					let temp_utc = temp_date.valueOf();
					if (temp_utc > 0 && !isNaN(temp_utc)) {
						this.et.updateEndCalendar(temp_utc);
					} else {
						this.et.reSetEndCalendar();
					}
				}
			}
		}
	}

	chooseDateStart(event) {
		if (event.target.className === 'react-datepicker__day react-datepicker__day--mon react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--tue react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--wed react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--thu react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--fri react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--sat react-datepicker__day--selected react-datepicker__day--weekend' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--sun react-datepicker__day--selected react-datepicker__day--weekend') {
			this.st.hideStartCalendar();
		}
	}

	chooseDateEnd(event) {
		if (event.target.className === 'react-datepicker__day react-datepicker__day--mon react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--tue react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--wed react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--thu react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--fri react-datepicker__day--selected' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--sat react-datepicker__day--selected react-datepicker__day--weekend' ||
			event.target.className === 'react-datepicker__day react-datepicker__day--sun react-datepicker__day--selected react-datepicker__day--weekend') {
			this.et.hideEndCalendar();
		}
	}

	initCalendarFromAndToPosition() {
		if (d3.selectAll('.highcharts-range-label>g').empty()) {
			let calendarSvgGroup = d3.select('#calendar-svg svg>g');
			let labelGroups = d3.selectAll('.highcharts-range-label');
			labelGroups.each(function (d, i) {
				d3.select(this).attr('transform', `translate(${i === 0 ? -10 : 140}, 0)`)
			})
			labelGroups.each(function (d, i) {
				d3.select(this).select('text').text(i === 0 ? 'FROM' : 'TO')
			})
			labelGroups.each(function (d, i) {
				d3.select(this).node().appendChild(i === 0 ? calendarSvgGroup.attr('transform', 'scale(0.6) translate(68, 5)').style('fill', '#002D72').node().cloneNode(true) : calendarSvgGroup.attr('transform', 'scale(0.6) translate(38, 5)').style('fill', '#002D72').node().cloneNode(true));
			})
		}
	}

	render() {
		return (
			<div id='container' className='indices-charting-container'>
				<div id='calendar-svg'>
					<SvgGenerator type='by-icon:calendar-dots' svgClass='by-svg-icon by-color-citi-blue x-scope bc-icon-0' svgSize='s' ></SvgGenerator>
				</div>
				<div className='cis-page-subTitle'>Index Performance Graph</div>
				<div onClick={this.chooseDateStart.bind(this)}>
					<Calendar onChange={date => this.startDateChange(date)} left={420} ref={(c) => this.st = c} isStartDate={true} />
				</div>
				<div onClick={this.chooseDateEnd.bind(this)}>
					<Calendar onChange={date => this.endDateChange(date)} left={470} ref={(c) => this.et = c} isStartDate={false} />
				</div>
				<div onClick={this.updateCalendar.bind(this)} onKeyUp={this.updateCalendar.bind(this)}>
					<ReactHighstock config={this.props.config} ref={(c) => this.chart = c} />
				</div>
			</div>);
	}
}

IndicesCharting.defaultProps = {
	config: {
		scrollbar: {
			liveRedraw: true
		},
		chart: {
			height: 72,
			spacingRight: 15
		},
		rangeSelector: {
			buttonPosition: { x: 0 },
			buttons: [{ type: 'month', count: 1, text: '1M' }, { type: 'month', count: 3, text: '3M' }, {
				type: 'month', count: 6, text: '6M'
			}, { type: 'ytd', text: 'YTD' }, { type: 'year', count: 1, text: '1Y' }, {
				type: 'all', text: 'ALL'
			}],
			buttonSpacing: 0,
			selected: 5,
			inputBoxBorderColor: 'white',
			inputBoxWidth: 80,
			inputBoxHeight: 18,
			inputDateFormat: '%d-%b-%y',
			buttonTheme: {
				r: 1,
				height: 16,
				width: 30,
				fill: 'none',
				stroke: '#ccc',
				'stroke-width': 1,
				style: {
					color: '#53565a',
					fontSize: '11px'
				},
				states: {
					select: {
						fill: '#1165e5',
						style: {
							color: 'white',
							fontSize: '11px'
						}
					}
				}
			},
		},
		credits: { enabled: false },
		series: [[]],
		yAxis: {
			opposite: false,
		},
		xAxis: {
			plotLines: []
		}
	}
};

IndicesCharting.propTypes = {
	config: PropTypes.object.isRequired
};

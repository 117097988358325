const Constant = {
    WINDSOW_SIZE_SM: '576',
    WINDSOW_SIZE_MD: '768',
    WINDSOW_SIZE_LG: '992',
    WINDSOW_SIZE_XL: '1200',

    MOBILE_SIZE_IPHONE_6: '375',
    MOBILE_SIZE_IPHONE_6_PLUS: '414',
    MOBILE_SIZE_IPHONE_IPAD: '768',
    MOBILE_SIZE_IPHONE_IPAD_PRO: '1024',
}

export const USER_AGENT = {
    WINDOWS: 'Windows',
    MOBILE: 'Mobile',
}

export const METERIAL_TYPES = {
    brochure: "Brochure",
    offerDocument: "Offer Document",
    kidEN: "Kid (EN)",
    kidDE: "Kid (DE)",
    factSheet: "Fact Sheet",
};

export const REOFFER_REGION = ['North America'];
const legalinfo1 = `See RESOURCES tab for Analyst Certification, Important Disclosures and non-US research analyst disclosures. Citi Research is a division of Citigroup Global Markets Inc.
(the "​Firm"​), which does and seeks to do business with companies covered in its research reports. As a result, investors should be aware that the Firm may have a conflict of interest that could affect the objectivity of this report. Investors should consider this report as only a single factor in making their investment decision. 
Certain products (not inconsistent with the author's published research) are available only on Citi's portals.`

const legalinfo2 = `Intended for Internal Use Only Please refer to the following URLs for our Market Commentary disclaimers:
 Sales and Trading General Communication - https://icg.citi.com/icg/data/documents/STExternalDiscl.pdf Please see the Resources panel for the above links. 
 This communication is issued by a member of the Sales and Trading, or syndicate departments of Citi. Sales and Trading or syndicate personnel are not Research analysts,
  and the information in this communication is not intended to constitute "?research"? as that term is defined by applicable regulations. All views, opinions and estimates expressed in this communication (i) may change without notice, and (ii) may differ from those views, opinions and estimates held or expressed by Citi or other Citi personnel. 
  Recipients of this Communication should obtain advice based on their own individual circumstances from their own tax, financial, legal another advisors before making an investment decision, and only make such decisions on the basis of the investor's own objectives, experience and resources. Where distribution of this communication is subject to the rules of the US Commodity Futures Trading Commission, this communication constitutes an invitation to consider entering into a derivatives transaction, where applicable, but is not a binding offer to buy/sell any financial instrument.`

const legalinfo3 = `Please refer to the following URLs for our Market Commentary disclaimers: Sales and Trading General Communication - https://icg.citi.com/icg/data/documents/STExternalDiscl.pdf Please see the Resources
   panel for the above links. This communication is issued by a member of the Sales and Trading, or syndicate departments of Citi. Sales and Trading or syndicate personnel are not Research analysts, and the information in 
   this communication is not intended to constitute "?research"? as that term is defined by applicable regulations. All views, opinions and estimates expressed in this communication (i) may change without notice, and (ii) may differ from those views, opinions and estimates held or expressed by Citi or other Citi personnel. Recipients of this Communication should obtain advice based on their own individual circumstances from their own tax, financial, legal another advisors before making an investment decision, and only make such decisions on the basis of the investor's own objectives, experience and resources. Where distribution of this communication is subject to the rules of the US Commodity Futures Trading Commission, this communication constitutes an invitation to consider entering into a derivatives transaction, where applicable, but is not a binding offer to buy/sell any financial instrument`

const legalinfo4 =`Please refer to the following URLs for our Market Commentary disclaimers: Sales and Trading General Communication - https://icg.citi.com/icg/data/documents/STExternalDiscl.pdf Please see the Resources panel
 for the above links. This communication is issued by a member of the Sales and Trading, or syndicate departments of Citi. Sales and Trading or syndicate personnel are not Research analysts, and the information in this communication is not intended to constitute "?research"? as that term is defined by applicable regulations. All views, opinions and estimates expressed in this communication (i) may change without notice, and (ii) may differ from those views, opinions and estimates held or expressed by Citi or other Citi personnel. Recipients of this Communication should obtain advice based on their own individual circumstances from their own tax, financial, legal another advisors before making an investment decision, and only make such decisions on the basis of the investor's own objectives, experience and resources. 
 Where distribution of this communication is subject to the rules of the US Commodity Futures Trading Commission, this communication constitutes an invitation to consider entering into a derivatives transaction, where applicable, but is not a binding offer to buy/sell any financial instrument.`

export const LEGAL_INFO_MAPPING = {
'/cv-content-web/eppublic/wrap/epfcms/html/1_ci9f669q': '',
'/cv-content-web/eppublic/wrap/epfcms/html/1_x9syqrnj': legalinfo1,
'/cv-content-web/eppublic/wrap/epfcms/html/1_9ey5u00m': legalinfo1,
'/cv-content-web/eppublic/wrap/epfcms/html/1_ue1jfg8s': '',
'/cv-content-web/eppublic/wrap/epfcms/html/1_rzjwzxdy': '',
'/cv-content-web/eppublic/wrap/epfcms/html/1_yahgleio': '',
'/cv-content-web/eppublic/wrap/epfcms/html/1_9z6p0x1q': legalinfo2,
'/cv-content-web/eppublic/wrap/epfcms/html/1_tlmxnho0': legalinfo3,
'/cv-content-web/eppublic/wrap/epfcms/html/1_ho1e6y4c': legalinfo4,
}
export const OPEN_LEGAL_INFO = "open legal info"
export const CLOSE_LEGAL_INFO = "close legal info"

export default Constant;
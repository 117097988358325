import React, { useMemo, useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(ReactHighcharts.Highcharts);
// this file same in auth site and public site, whenever need to modify this file, need sync too projects
// color from https://www.citivelocity.com/elemental/core/color
const PieChart = ({ title, data, dataName = 'CPW' }) => {
	const dataToShow = useMemo(() => {
		let tempArr = [];
		if (data && Object.keys(data).length) {
			const keys = Object.keys(data);
			keys.forEach(key => {
				let oneItem = [key, data[key]];
				tempArr.push(oneItem);
			});
		}
		return tempArr;
	}, [data]);
	const config = {
		chart: {
			type: 'pie',
			options3d: {
				enabled: true,
				alpha: 45,
				beta: 0
			},
			spacingBottom: 150,
			ignoreHiddenSeries: false
		},
		title: {
			text: title,
			align: 'left',
			style: {
				fontSize: '14px',
				fontWeight: 'bold'
			}
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		tooltip: {
			pointFormat: '{series.name}: {point.percentage:.2f}%',
			style: {
				fontFamily: 'interstate, Arial, Helvetica, sans-serif'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: false,
				cursor: 'default',
				depth: 35,
				dataLabels: {
					enabled: true,
					format: '{point.percentage:.2f}%',
					style: {
						fontWeight: 'normal',
						fontFamily: 'interstate, Arial, Helvetica, sans-serif'
					}
				},
				point: {
					events: {
						legendItemClick: event => {
							event.preventDefault();
						}
					}
				},
				size: 200,
				showInLegend: true,
				borderColor: '#ffffff',
				borderWidth: 1,
				colors: [
					'#59dbff',
					'#00b0b9',
					'#00b755',
					'#84bd00',
					'#c4d600',
					'#ffcd00',
					'#ed8b00',
					'#ff4944',
					'#c6007e',
					'#a21ebc',
					'#0095d7',
					'#20f4ff',
					'#00ea6c',
					'#a8f000',
					'#eaff0a',
					'#ffe166',
					'#ffb845',
					'#ff7a75',
					'#ff2db3',
					'#c366e0'
				]
				//    ["citi-cyan","teal",    "forest",  "green",   "olive",   "yellow",  "orange",   "red",     "plum",    "purple", blue100, teal-100, forest-100, green-100,olive-100, yellow-100,orange-100,red-100,plum-100,purple-100],
				// colors:["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1","#00ffff","#ff1493","#ff4500"],
				//     ["blue", "black", "#light green", "#orange", "#pink blue", "#watermelon", "#green-yellow", "#dark green","#red",  "#bo he",cyan,deep pink,orangered],
			}
		},
		series: [
			{
				type: 'pie',
				name: dataName,
				data: dataToShow
			}
		],
		legend: {
			enabled: true,
			layout: 'horizontal',
			labelFormat: '{name} {percentage:.2f}%',
			align: 'center',
			verticalAlign: 'top',
			alignColumns: false,
			margin: 30,
			floating: true,
			maxHeight: 150,
			navigation: {
				enabled: false,
				animation: false
			},
			x: 0,
			y: 270,
			itemHoverStyle: {
				cursor: 'default',
				fontWeight: 'normal',
				fontFamily: 'interstate, Arial, Helvetica, sans-serif'
			},
			itemStyle: {
				cursor: 'default',
				fontWeight: 'normal',
				fontFamily: 'interstate, Arial, Helvetica, sans-serif'
			}
		},
		credits: {
			enabled: false
		}
	};
	return <ReactHighcharts config={config} />;
};

export default PieChart;

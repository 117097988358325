import React, { useState, useEffect } from 'react';
import ReactDataGrid from 'react-data-grid/dist/react-data-grid.min.js';
import HtmlFormatter from '../cisCommon/htmlFormatter';
import { Tooltip,  } from 'antd';
import moment from 'moment'
// const { Paragraph } = Typography
const percentToNumber = percent => percent.includes('%') ? Number(percent.split('%')[0] / 100) : Number(percent)
const FORMAT = 'DD-MMM-YY'
const MoreData = props => {

	const [exposureTableBody, setBody] = useState([])
	const [sourceData, setSourceData] = useState([])
	const [exposureColumn, setExposureColumn] = useState([{ key: 'date', name: 'Dates', sortable: true, formatter: HtmlFormatter }])
	const {
		exposure,
		mqaTicker
	} = props;




	const { exposureList, exposurePlain } = exposure;
	let exposureHasData =
		exposure &&
		exposureList &&
		exposureList.length > 1 &&
		JSON.stringify(exposurePlain) !== '{}';
	useEffect(() => {
		let exposureTableBody = []
		let exposureColumnTemp = [...exposureColumn];
		let sourceData = []
		if (exposureHasData) {
			const headers = exposureList[0].values;
			headers.forEach(header => {
				const current = exposurePlain[header];
				const { displayName, explain } = current;
				exposureColumnTemp.push({
					key: header,
					// name:<span>{displayName} {explain &&
					// 		<Tooltip title={explain} color={'#108ee9'}>
					// 			<span className='question-mark' >?</span>
					// 		</Tooltip>}</span>,
					name: <Header displayName={displayName} explain={explain} />,
					// formatter:<span>
					// 	{displayName} {explain &&
					// 	<Tooltip title={explain} color={'#108ee9'}>
					// 		<span className='question-mark' >?</span>
					// 	</Tooltip>}
					// </span>,
					formatter: TextFormatter,
					sortable: true,
					type: 'number',
				});
			});
			exposureList.forEach((exposureData, i) => {
				if (i !== 0) {
					// let {values} = exposureData;
					// 	let valueBody = [];
					// 	values.forEach((value, index) => {
					// 		valueBody.push(
					// 			<td className="by-col tl" key={`${index}-${value}`}>
					// 				{value}
					// 			</td>
					// 		);
					// 	});
					// 	let item = (
					// 		<tr key={exposureData.date} className="by-row">
					// 			<td className="by-col tl">{exposureData.date}</td>
					// 			{valueBody}
					// 		</tr>
					// 	);
					// 	exposureTableBody.push(item);
					let { values } = exposureData;
					let valueBody = { date: exposureData.date };
					values.forEach((value, index) => {
						valueBody[headers[index]] = value;
					});

					exposureTableBody.push(valueBody);
					sourceData.push(valueBody);
				}
			});
		}
		setBody(exposureTableBody)
		setSourceData(sourceData)
		setExposureColumn(exposureColumnTemp)
	}, [exposure])
	const sort = (name, dir) => {
		let exposureTableBodyTemp = [].concat(sourceData)
		if (dir === 'NONE') {
			setBody(exposureTableBodyTemp)
			return exposureTableBodyTemp
		}
		let sortedData = exposureTableBodyTemp.sort((o1, o2) => {
			const v1 = o1[name]
			const v2 = o2[name]

			const result = name !== 'date'
				? percentToNumber(v1) - percentToNumber(v2)
				: moment(v1,FORMAT) - moment(v2,FORMAT)


			return result * (dir === 'ASC' ? 1 : -1)
		})
		setBody(sortedData)
		return sortedData
	}

	return (
		<div className="pr min-h-50 composition-detail-page more-data">
			{exposureHasData && [
				<div style={{ margin: '15px 0 1px 0' }}>
					<div className="cis-page-subTitle ib"></div>
					<button
						className="by-button by-bluefill by-xl ib fr"
						onClick={() => {
							let url = `/cis-pub-service/v2/composition/download/exposure/${mqaTicker}`;
							window.open(url);
						}}
					>
						EXPORT
						</button>
				</div>,

				<ReactDataGrid
					idProperty="date"
					columns={exposureColumn}
					rowGetter={rowIndex => exposureTableBody[rowIndex]}
					rowsCount={exposureTableBody.length}
					onGridSort={sort}
					rows={exposureTableBody}
					minHeight={325}
				/>
			]}

		</div>
	);

}
class TextFormatter extends React.Component {
	render() {
		return (
			<span
				style={{
					textAlign: 'left',
					display: 'inline-block',
					width: '100%'
				}}
			>
				{this.props.value}
			</span>
		);
	}
}
class Header extends React.Component {
	render() {
		const { displayName, explain } = this.props;
		return (
			<span className='header-in-more-data'>
				{/* <Paragraph ellipsis title={displayName}>
					{displayName}{' '}
				</Paragraph> */}
				<span title={displayName} className='header-formatter'>{displayName}</span>
				{explain && (
					<Tooltip title={explain} color={'#108ee9'}>
						<span className="question-mark">?</span>
					</Tooltip>
				)}
			</span>
		);
	}
}

export default MoreData;

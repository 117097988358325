import { SERVER_ERROR } from '../../actions/ActionTypes';

const initialState = {
    isServerError: false,
};

const commonsReducers = (state = initialState, action) => {
    switch (action.type) {
      case SERVER_ERROR:
        return {
          ...state,
          isServerError: action.isError,
        };
      default:
        return state;
    }
}

export const getIsServerError = state => state.commonsReducers.isServerError

export default commonsReducers;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './GridCol.css'
import Portlet from '../portlet/Portlet';
import { connect } from 'react-redux';
import Grid from './Grid';

export default class GridCol extends Component {

    static propsTypes = {
        xl: PropTypes.number,
        lg: PropTypes.number,
        md: PropTypes.number,
        sm: PropTypes.number,
        xs: PropTypes.number,
    }

    render() {
        const {width, leafFlag, uiRows, portlet} = this.props;
        const widthStr = width.toString();
        let layouts = widthStr.split('-');
        if (!widthStr.includes('-')) {
            layouts = [];
            layouts[3] = widthStr;
        }
        const colStyle = portlet || uiRows ? 'portlet' : 'nonPortlet';

        //new rows in a column
        if (leafFlag ==="FALSE") {
            return (
                <div className={`col-${layouts[0]} col-sm-${layouts[1]} col-md-${layouts[2]} col-lg-${layouts[3]} col-xl-${layouts[4]} ${colStyle}`}>
                    <Grid>
                        {
                            uiRows.map(row => (
                                <Grid.Row key={row.rowId} 
                                    alignment={row.alignment} 
                                    background={row.background}>
                                    {
                                        row.uiCols.map(col => (
                                            <Grid.Col key={col.columnId} 
                                                width={col.width} 
                                                leafFlag={col.leafFlag}
                                                uiRows={col.uiRows}
                                                portlet={col.portlet} 
                                            />
                                        ))

                                    }
                                </Grid.Row>
                            ))
                        }
                    </Grid>
                </div>
                    
            )
        }
        //render portlet
        return (
            <div className={`col-${layouts[0]} col-sm-${layouts[1]} col-md-${layouts[2]} col-lg-${layouts[3]} col-xl-${layouts[4]} ${colStyle}`}>
                { this._renderPortlet() }
            </div>
        );
    }

    _renderPortlet() {
        const { portlet } = this.props;
        if (!portlet) return null;
        if (portlet.type) return (<Portlet key={portlet.id} portletType={portlet.type} config={portlet.config_json}></Portlet>)
    }
}
import React from 'react'
import HtmlFormatter from '../cisCommon/htmlFormatter'
import Utils from '../cisCommon/utils'

export default class IndicesDocument extends React.Component{
	static defaultProps = {
		documentData:[],
		maxHeight:1
	};
	
	constructor(props){
		super(props);
	}
	
	render(){
		const {documentData,maxHeight} = this.props;
		let maxHeightAdjust = maxHeight===0?1:maxHeight;
		return(
			<div>
				<div className="cis-page-title">Documents</div>
				<div className="cis-indices-realted" style={{height:maxHeightAdjust*20}}>
					{this.renderDoc(documentData)}
				</div>
			</div>
		)
	}	
	onDownloadClick = (attachmentName) =>{
		
		try{
			const {trackPageEvent} = window.RETAIL;
			const {region,userType} = Utils.getInfoName();
            trackPageEvent(null,'Downloads',`DownloadsDOC/${window.location.pathname.split('/')[1]}${region}${userType}`,attachmentName)
        }catch(err){

        }
    }
	renderDoc(docs){
		if(docs && docs.length>0){				
		    return(
				docs.map((doc,index)=>{
					let icon = Utils.getIconByType(doc.fileType,'s'); 
					return(
						<div key={index} className="h15 pl10 pt5 cis-indices-mockTable"
							onClick={(_)=>{this.onDownloadClick(doc.attachmentName)}}
						>
							<a className="w12 tcell" href={doc.documentUrl}>{icon}</a>
							<a className="cis-document-text tcell" href={doc.documentUrl}><HtmlFormatter value={doc.attachmentName}  title={doc.attachmentName}/></a>
						</div>
					)
				})
			)
		}
		else{
			return(
					<div className="h20 pl10 tc">No documents available</div>		
			)
		}
	}
}
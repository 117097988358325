import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers'; 


const initialState = {};


//rootReducer is a combined reducer, we will define all the action type in one file for making sure that the action type is unique in the reducers.
const store = createStore(
    rootReducer,
    initialState,

  );
export default store;
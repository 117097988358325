import {
    combineReducers
} from 'redux';
import eventReducer from './event/eventReducer';
import commonsReducers from './commons/commonsReducers';
import indicesDetailReducer from '../components/cisIndices/reducer/indicesReducer'

export default combineReducers({

    eventReducer,

    commonsReducers,
    indicesDetailReducer
});
import { citiRequest } from '../utils/Request';

export const previewPageById = (id, previewNonce,siteId = 'cis-public') => {
    const url = `/rcs/v1/content/${siteId}/preview/${id}?preview=true&preview_nonce=${previewNonce}`;
    return citiRequest.get(url);
}

export const getContentByBusinessId = (businessId,content,siteId = 'cis-public') => {
    const url = `/rcs/v1/content/${siteId}/${content}/businessId/${businessId}`;
    return citiRequest.get(url);
}
export const getContentById = (id,siteId = 'cis-public') => {
    const url = `/rcs/v1/content/${siteId}/id/${id}`;
    return citiRequest.get(url);
}
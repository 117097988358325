import React from 'react'
import 'rc-collapse/assets/index.css'
import Collapse, { Panel } from 'rc-collapse'

export default class IndicesAccordion extends React.Component{
  
  static defaultProps = {
    data: [],

  };
  constructor(props){
    super(props);
    this.state = {
      activeKey: ['1'],
    }
  };

  shouldComponentUpdate(nextProps, nextState){
    let shouldUpate = ((nextProps.data !== this.props.data) || (nextState.activeKey !== this.state.activeKey));
    return shouldUpate;
  };

  getItems(){	  
    const items = [];
    const data = this.props.data;
    for (let i = 0; i < data.length; i++) {
      const key = i + 1;
      if(key == 4 && data[i]['text']){
        items.push(
          <Panel header={data[i]['title']} key={key}>
            <img width="600px" src={data[i]['text']} />
          </Panel>
        );
      }
      else if(data[i]['text']){
        items.push(
          <Panel header={data[i]['title']} key={key}>           
      		 <p dangerouslySetInnerHTML={{__html:data[i]['text'] || '--'}}></p>
          </Panel>
        );
      }
    }
    return items;
  };
  
  changeAccodianItemHeight(itemsCount){
	  	let newHeight ="";
	    switch(itemsCount){
    	case 5:
    		newHeight = "accodian-201"
    		break;
    	case 4:
    		newHeight = "accodian-233"
    		break;
    	case 3:
    		newHeight = "accodian-265"
    		break;
    	case 2:
    		newHeight = "accodian-297"
    		break;
    	case 1:
    		newHeight = "accodian-329"
	    }
	    return newHeight
  }

  onChange(activeKey) {
    this.setState({
      activeKey
    });
  };
  
  countValidDataLength(data){
	  let itemlength = 0;
	  data.map((item)=>{
		 if(item.text){
			 itemlength++;
		 }
	  })
	  return itemlength;
  }
  

  render(){
	let  itemHeightClass = "";
    const {data} = this.props
    let itemCount = this.countValidDataLength(data)
    itemHeightClass = this.changeAccodianItemHeight(itemCount)
    return (
        <div className="cis-accordion">
	        <div className={itemHeightClass}>
	          <Collapse
	            accordion={true}
	            onChange={this.onChange.bind(this)}
	            activeKey={this.state.activeKey}>
	               {this.getItems()}
	            </Collapse>
            </div>
        </div>
    )
  };
}

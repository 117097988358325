import React from 'react'
import HtmlFormatter from '../cisCommon/htmlFormatter'

export default class IndicesAttributes extends React.Component{
 render(){
    return (
        <div>
          <div className="cis-page-subTitle">Index Attributes</div>          
          <div className="cis-attribute-table">{this.renderTable()}</div>          
        </div>
    )
  };
  
  renderTable(){
  	let items;
    if(this.props.data){
      items = this.props.data.map((item,index)=>{
        return <tr key={index} className="by-row">
                 <td className="by-col tl w150p" style={{fontWeight:((index===0)?700:500)}}>
                 	<HtmlFormatter value = {item.title}/>
                 </td>
                 <td className="by-col tl" style={{fontWeight:((index===0)?700:500)}}>
                    <HtmlFormatter value = {item.text}/>
                 </td>
               </tr>;
      });
    }
    return (<table className="cis-table-blue by-table by-table-striped by-table-around-bordered by-table-hover by-table-l">
             <tbody className="by-body">{items}</tbody>
           </table>)
   }
}


const indicesCPState = {

    exposure:{
        exposureList:null,
        exposurePlain:null
    }
};
const indicesDetailCP = (state = indicesCPState, action) => {
    switch (action.type) {
        case 'RECEIVE_INDICES_DETAIL_CP':
            return {
                ...state,
                exposure:action.exposure
            };
        default:
            return state;
    }
};
export default indicesDetailCP
import React, { useReducer, useEffect, useState } from 'react'
import IndicesDetail from '../../components/cisIndices/indicesDetail'
import { getStrategyDetail } from '../../services/cis';
import CisIndicesBanner from './cisIndicesBanner'

import Utils from '../../components/cisCommon/utils'

const checkisFetching = (actionType, isFetching) => ({
    type: actionType,
    isFetching
});

const receiveIndicesDetailOV = (json, isFetching) => ({
    type: 'RECEIVE_INDICES_DETAIL_OV',
    allData: (json && json.list && json.list.length > 0) ? json.list[0] : [],
    isFetchIndicesDetailsOv: isFetching
});

let setAccordionData = function (accordionData, updateData) {
    for (let i = 0; i < accordionData.length; i++) {
        accordionData[i]['text'] = updateData[accordionData[i]['key']];
    }
    return Object.assign([], accordionData);
};
let setAttributeData = function (attributeData, updateData) {
    for (let i = 0; i < attributeData.length; i++) {
        let key = attributeData[i]['key'];
        let subKey = attributeData[i]['subKey'];
        attributeData[i]['text'] = subKey ? updateData[key][subKey] : updateData[key];
    }
    return Object.assign([], attributeData);
};



/**
 * reducers
 * @param {*} state 
 * @param {*} action 
 */




const fetchIndicesDetailOV = async (mqaTicker, regionId, investorTypeId, dispatch) => {

    dispatch(checkisFetching('CHECK_ISFETCHING_INDICES_DETAILOV', true));
    const rsp = await getStrategyDetail(mqaTicker, regionId, investorTypeId)
    dispatch(receiveIndicesDetailOV(rsp, false))

}

/**
 * Component
 * @param {*} props 
 */
const IndiceDetailContainer = (props) => {

/**
 * initial state
 */
    const indicesDetailOvState = {
        accordionData: [
            { key: 'indexDescription', title: 'Index Overview', text: '' },
            { key: 'keyFeature', title: 'Key Features', text: '' },
            { key: 'strategyRationale', title: 'Strategy Rationale', text: '' },
            { key: 'indexSchematic', title: 'Index Schematic', text: '' },
            { key: 'certainRisk', title: 'Risk Factors', text: '' }],
        attributeData: [
            { key: 'indexLevel', title: 'Index Level', text: '' },
            { key: 'assetClass', title: 'Asset Class', text: '' },
            { key: 'marketingCategory', title: 'Strategy Type', text: '' },
            { key: 'marketingSubCategory2', title: 'Strategy', text: '' },
            { key: 'bbgTicker', title: 'Bloomberg Ticker', text: '' },
            { key: 'currency', title: 'Currency', text: '' },
            { key: 'returnType', title: 'Return Type', text: '' },
            { key: 'liveDate', title: 'Live Date', text: '' },
            { key: 'baseDate', title: 'Start Date', text: '' },
            { key: 'rebalanceFrequency', title: 'Rebalance Frequency', text: '' },
            // {key: 'benchmark', title: 'Benchmark', text: ''},
            { key: 'indexAdmin', title: 'Index Administrator', text: '' },
            { key: 'primaryCalculationAgent', title: 'Calculation Agent', text: '' }],
        documentData: [],
        liveDate: '',
        strategyName: "",
    };

    const indicesDetailOV = (state, action) => {

        switch (action.type) {
            case 'RECEIVE_INDICES_DETAIL_OV':

                return {
                    ...state,
                    accordionData: setAccordionData(state.accordionData, action.allData),
                    attributeData: setAttributeData(state.attributeData, action.allData),
                    liveDate: action.allData.liveDate,
                    disclaimer: action.allData.disclaimer,
                    footNote: action.allData.footnote,
                    documentData: action.allData.documentList,
                    strategyName: action.allData.magnetStrategyName,
                    showComposition: action.allData.showComposition,
                    showTop10Composition: action.allData.showTop10Composition,
                    isFetchIndicesDetailsOv: false
                };

            case 'CHECK_ISFETCHING_INDICES_DETAILOV':
                return {
                    ...state,
                    accordionData: indicesDetailOvState.accordionData,
                    attributeData: indicesDetailOvState.attributeData,
                    liveDate: '',
                    documentData: [],
                    strategyName: '',
                    disclamier: '',
                    footNote: '',
                    isFetchIndicesDetailsOv: action.isFetching
                };
            default:
                return state;
        }
    };


    const [state, dispatch] = useReducer(indicesDetailOV, indicesDetailOvState)
    const [cisIndiceBanner, setCisIndiceBanner] = useState({ name: 'CIS-IndiceBanner', value: null })


    useEffect(() => {
        try {
            const { region, userType } = Utils.getInfoName();
            const { RETAIL: { trackPageViewByPageName }, location: { pathname } } = window
            trackPageViewByPageName(`/${pathname.split('/')[1]}${region}${userType}`);
        } catch (err) {

        }

    }, [cisIndiceBanner.name, window.location.pathname])

    return (
        <div>
            <CisIndicesBanner />
            <div className="blueberry cis-page">
                <IndicesDetail {...state} dispatch={dispatch} fetchIndicesDetailOV={fetchIndicesDetailOV} />
            </div>
        </div>
    )
}

export default IndiceDetailContainer
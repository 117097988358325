import {
    getStrategyDetail,
    getCvUserProfile,
    getExposure
} from "../../../services/cis";


export const checkisFetching = (actionType, isFetching) => ({
    type: actionType,
    isFetching
});

export const receiveIndicesDetailOV = (json, isFetching) => ({
    type: 'RECEIVE_INDICES_DETAIL_OV',
    allData: (json && json.list && json.list.length > 0) ? json.list[0] : [],
    isFetchIndicesDetailsOv: isFetching
});


const receiveLoginUserInfo = (json) => ({
    type: 'REVEIVE_LOGINUSER_INFO',
    fullName: json.fullName,
    loggedIn: json.loggedIn
});

const menuUserLogoutSuccess = () => ({
    type: 'MENU_USERLOGOUT_SUCCESS',
    fullName: '',
    loggedIn: false
});

const menuUserLogoutFail = () => ({
    type: 'MENU_USERLOGOUT_FAIL',
    fullName: '',
    loggedIn: false
});

//get all Indices detail composition data 
const receiveIndicesDetailCP = (json, isFetching) => ({
    type: 'RECEIVE_INDICES_DETAIL_CP',
    exposure:json
});

export const fetchIndicesDetailOV = (mqaTicker, regionId, investorTypeId) => async dispatch => {
    dispatch(checkisFetching('CHECK_ISFETCHING_INDICES_DETAILOV', true));

    const rsp = await getStrategyDetail(mqaTicker, regionId, investorTypeId)
    if (rsp.result === 'succeed') {
        dispatch(receiveIndicesDetailOV(rsp, false))
    }
};


export const fetchLoginUserInfo = (regionId, investorTypeId) => async dispatch => {
    const rsp = await getCvUserProfile(regionId, investorTypeId)
    if (rsp.result === 'succeed') {
        dispatch(receiveLoginUserInfo(rsp))
    }
};

//to be finish api call
export const menuUserLogout = () => async dispatch => {
    const rsp = await Promise.resolve()
    if (rsp.result === 'succeed') {
        dispatch(menuUserLogoutSuccess())
    } else {
        dispatch(menuUserLogoutFail())
    }
};

export const fetchIndicesDetailCP = (mqaTicker, regionId, investorTypeId) => async dispatch => {
    dispatch(checkisFetching('CHECK_ISFETCHING_INDICES_COMPOSITION', true));

    const rsp = await getExposure(mqaTicker, regionId, investorTypeId)
    if (rsp.result === 'succeed') {
        dispatch(receiveIndicesDetailCP(rsp, false))
    }
};

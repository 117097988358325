import React from 'react';
import IndicesAccordion from './indicesAccordion';
import IndicesAttributes from './indicesAttributes';
import IndicesCharting from './indicesCharting';
import IndicesPerformance from './indicesPerformance';
import IndicesDetailLinks from './indicesDetailLinks';
import IndicesDetailStandardised from './indicesDetailStandardised';
import IndicesDetailDocument from './indicesDetailDocument';
import IndicesDisclaimer from './indicesDisclaimerAndFootNote';
import Utils from '../cisCommon/utils.js';
import LoadingMask from '../cisCommon/loadingmask';
import { getCisUrl } from '../../utils/Request';
import SvgGenerator from '../svgGenerator/svgGenerator';
import HtmlFormatter from '../cisCommon/htmlFormatter';

const detailIndicePathname = window.location.pathname.split('/')
class Overview extends React.Component {

    componentWillMount() {
        const { mqaTicker, fetchIndicesDetailLinkList, fetchCombinedDetailInformation } = this.props;
        const { regionId, investorTypeId } = Utils.getUserInfo();
        fetchIndicesDetailLinkList(mqaTicker, regionId, investorTypeId);
        fetchCombinedDetailInformation(mqaTicker, regionId, investorTypeId);
    }

    componentDidMount() {
        // const {actions} = this.props;
        // actions.receiveIndicesDetailLinkList('');
    }

    onDownloadClick = (attachmentName) => {

        try {
            const { trackPageEvent } = window.RETAIL;
            const { region, userType } = Utils.getInfoName();
            trackPageEvent(null, 'Downloads', `DownloadsDOC/${window.location.pathname.split('/')[1]}${region}${userType}`, attachmentName)
        } catch (err) {

        }
    }

    renderDocumentsDisclaimer = (linkListsLength, isFetchIndicesDetailsOv, documentData, maxHeight, linkLists, disclaimer, footNote) => {
        return (
            <div>
                <div className='bs p10-0 clearfix pr' style={{ display: (linkListsLength !== 0) ? 'block' : 'none' }}>
                    <LoadingMask show={isFetchIndicesDetailsOv} />
                    <div className='bs w40p fl'>
                        <IndicesDetailDocument documentData={documentData} maxHeight={maxHeight} />
                    </div>
                    <div className='bs w60p pl20 fl'>
                        <IndicesDetailLinks linkLists={linkLists} maxHeight={maxHeight} />
                    </div>
                    <IndicesDisclaimer disclaimer={disclaimer} footNote={footNote} isDisclaimer={false} />
                </div>
                <div className='bs p10-0 clearfix pr' style={{ display: (linkListsLength === 0) ? 'block' : 'none' }}>
                    <LoadingMask show={isFetchIndicesDetailsOv} />
                    <div className='bs w100p fl'>
                        <IndicesDetailDocument
                            documentData={documentData}
                            maxHeight={maxHeight}
                            showMask={isFetchIndicesDetailsOv}
                        />
                    </div>
                    <IndicesDisclaimer disclaimer={disclaimer} footNote={footNote} isDisclaimer={false} />
                </div>
            </div>
        )
    }

    renderDetailIndiceDocument = () => {
        const docs = this.props.detailIndiceDocument
        if (docs && docs.length > 0) {
            return (

                docs.map((doc, index) => {
                    return (
                        <div key={index} className="pl10 pt5 cis-indices-mockTable"
                            onClick={(_) => { this.onDownloadClick(doc.DOCUMENT_NAME) }}
                        >
                            <a className="w12 tcell" href={doc.DOCUMENT_URL}>
                                <SvgGenerator type="by-custom-color:file-pdf-red" svgClass='by-svg-icon' svgSize='s' />
                            </a>
                            <a className="cis-document-text tcell" href={doc.DOCUMENT_URL}><HtmlFormatter value={doc.DOCUMENT_NAME} title={doc.DOCUMENT_NAME} /></a>
                        </div>
                    )
                })
            )
        }
        else {
            return (
                <div className="h20 pl10 tc">No documents available</div>
            )
        }

    }

    renderEmailDetailIndiceTable = (emailCitiRadarTable) => {
        if (!emailCitiRadarTable || Object.keys(emailCitiRadarTable).length <= 0) return null
        return (
            <table style={{ width: "70%" }}>
                <thead class="citiRadarTable">
                    <tr style={{ background: "#012060" }}>
                        {
                            emailCitiRadarTable.header.map((item, index) => {
                                return (
                                    <th style={{
                                        textAlign: "center",
                                        textAlign: index === 0 ? 'left' : 'center',
                                        fontWeight: '700'
                                    }}
                                        dangerouslySetInnerHTML={{ __html: item['title'] }}></th>
                                )
                            })
                        }
                    </tr>
                    <tr style={{ background: "#1e4677" }}>
                        <td colspan={emailCitiRadarTable.header.length}><div style={{ padding: "8px 6px" }}>{emailCitiRadarTable.title}</div></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        emailCitiRadarTable.data.map(trData => {
                            return (
                                <tr>
                                    {
                                        trData.map((tdData, index) => {
                                            const weight = index > 1
                                                ? (100 - 15 - 10) / (trData.length - 2)
                                                : index == 0
                                                    ? 15
                                                    : 10
                                            const color = tdData['style'] && tdData['style']['color'] ? tdData['style']['color'] : 'inherit';
                                            return (
                                                <td style={{
                                                    fontSize: '12px',
                                                    fontFamily: "Calibri",
                                                    fontWeight: '400',
                                                    padding: '8px 6px 8px 6px',
                                                    border: '0.5px solid #333',
                                                    width: `${weight}%`,
                                                    color: `${color}`,
                                                    textAlign: index === 0 ? 'left' : 'center'
                                                }}>{tdData.value}</td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }


    render() {

        console.log(this.props, "detail ov props")

        const {
            combinePerformance,
            accordionData,
            attributeData,
            linkLists,
            documentData,
            footNote,
            disclaimer,
            isFetchIndicesDetailsOv,
            isFetchingCombineInformation,
            mqaTicker,
            detailIndiceCommentary,
            detailIndiceImage,
            detailIndiceDocument,
            detailIndiceDisclaimer,
            emailCitiRadarTable,
            liveDate
        } = this.props;
        const { chartData, indicesStatus } = combinePerformance;
        let linkListsLength = linkLists ? linkLists.length : 0;
        let documentDataLength = documentData ? documentData.length : 0;
        let maxHeight = linkListsLength > documentDataLength ? (linkListsLength > 5 ? 5 : linkListsLength) : (documentDataLength > 5 ? 5 : documentDataLength);
        const exportButtonShow = (chartData && chartData.length > 0) ? 'inline' : 'none';
        const displayDataNotAvailable = (!isFetchingCombineInformation && chartData && chartData.length === 0) ? 'block' : 'none';
        const { newChartData, newPerformance, newStandardised } = this.processData(combinePerformance);
        const { hasStandardisedPerf } = indicesStatus;
        let maxHeightAdjust = (detailIndiceDocument && detailIndiceDocument.length) || 1;

        return (
            <div>
                <div className='bs p10-0 clearfix'
                    style={{ display: hasStandardisedPerf ? 'block' : 'none' }}>
                    <div className='bs w100p fl pr'>
                        <LoadingMask show={isFetchingCombineInformation} />
                        <IndicesDetailStandardised standardised={newStandardised}
                            combinedReady={!isFetchingCombineInformation} liveDate={liveDate}/>
                    </div>
                </div>
                <hr style={{ display: hasStandardisedPerf ? 'block' : 'none' }} />
                <div className='bs p10-0-0 clearfix'>
                    <div className='cis-page-title'
                        style={{ display: hasStandardisedPerf ? 'block' : 'none' }}>Past Performance Information
                    </div>
                    <div className='bs w60p fl pr h100p'>
                        <LoadingMask show={isFetchingCombineInformation} />
                        <button className='by-button by-bluefill by-xl cis-charting-downloadbutton'
                            style={{ display: exportButtonShow }} onMouseUp={this.downloadDataClick.bind(this)}>EXPORT
                        </button>
                        <IndicesCharting
                            data={newChartData}
                            mqaTicker={mqaTicker}
                            combinedReady={!isFetchingCombineInformation}
                        />
                        <div className="noData-charting" style={{ display: displayDataNotAvailable }}>Data Not Available
                        </div>
                    </div>
                    <div className='bs w40p pl20 fl pr'>
                        <LoadingMask show={isFetchingCombineInformation} />
                        <IndicesPerformance performance={newPerformance}
                            combinedReady={!isFetchingCombineInformation} />
                    </div>
                </div>
                {
                    detailIndicePathname[2] === 'detail'
                        ? null
                        : <div className='bs clearfix pr'>
                            <LoadingMask show={isFetchIndicesDetailsOv} />
                            <IndicesDisclaimer disclaimer={disclaimer} footNote={footNote} isDisclaimer={true} />

                        </div>
                }
                <hr />
                {detailIndicePathname[2] === 'detail'
                    && <React.Fragment><div className='bs clearfix pr'>
                        <div className="w100p">
                            <div className="detail-indice-index-commentary">Index Highlights</div>
                            <div dangerouslySetInnerHTML={{ __html: detailIndiceCommentary }}></div>
                        </div>
                    </div>
                        <hr />
                    </React.Fragment>}
                <div className='bs  p10-0-0 clearfix w100p pr'>
                    <LoadingMask show={isFetchIndicesDetailsOv} />
                    <div className='bs w60p fl'>
                        <IndicesAccordion data={accordionData} />
                    </div>
                    <div className='bs w40p pl20 fl'>
                        <IndicesAttributes data={attributeData} />
                    </div>
                </div>
                <hr />

                {
                    detailIndicePathname[2] === 'detail'
                        ? <React.Fragment>
                            <div className='bs p10-0 clearfix pr'>
                                <div className="cis-page-title">Documents</div>
                                <div className="cis-indices-realted" style={{ height: maxHeightAdjust * 20 }}>{this.renderDetailIndiceDocument()}</div>
                            </div>
                            <hr />
                        </React.Fragment>
                        : this.renderDocumentsDisclaimer(linkListsLength, isFetchIndicesDetailsOv, documentData, maxHeight, linkLists, disclaimer, footNote)

                }
                {
                    this.renderEmailDetailIndiceTable(emailCitiRadarTable)
                }
                {
                    detailIndiceDisclaimer
                    && <React.Fragment>
                        <div className="email-detail-disclaimer" dangerouslySetInnerHTML={{ __html: detailIndiceDisclaimer }}></div>
                        <div className='bs clearfix pr'>
                            <LoadingMask show={isFetchIndicesDetailsOv} />
                            <IndicesDisclaimer disclaimer={disclaimer} footNote={footNote} isDisclaimer={true} style={{ color: '#888888' }} />

                        </div>
                        <hr />
                    </React.Fragment>
                }


            </div>
        );

    }

    downloadDataClick(e) {
        if (e.button !== 2) {
            const url = this.getPdfUrl();
            try {
                const { trackPageEvent } = window.RETAIL;
                const { region, userType } = Utils.getInfoName();
                trackPageEvent(null, 'Exports', `Exports/${window.location.pathname.split('/')[1]}${region}${userType}`, url)
            } catch (err) {

            }
            url && window.open(url);
        }
    }

    processData(combinePerformance) {
        const { indicesStatus = {}, chartData, performance, standardised } = combinePerformance;
        const { hasStandardisedPerf = false, oneYearDataCheck = false } = indicesStatus;
        let newChartData = [];
        let newPerformance = {
            columnKeyArray: [],
            columnTitleArray: [],
            dataMap: []
        };
        let newStandardised = {
            columnKeyArray: [],
            columnTitleArray: [],
            dataMap: []
        };

        if (hasStandardisedPerf) {
            if (oneYearDataCheck && this.verifyIfInOneYearPerid(chartData)) {
                newChartData = [];
                newPerformance = {
                    columnKeyArray: [],
                    columnTitleArray: [],
                    dataMap: []
                };
                newStandardised = {
                    columnKeyArray: [],
                    columnTitleArray: [],
                    dataMap: []
                };
            }
            else {
                if (this.checkGraphData(chartData) && this.checkPerformancehasData(performance) && this.checkStandarisedhasData(standardised)) {
                    newChartData = this.processChartData(combinePerformance);
                    newPerformance = performance;
                    newStandardised = standardised;
                }
            }
        }
        else {
            if (this.checkGraphData(chartData) && this.checkPerformancehasData(performance)) {
                newChartData = this.processChartData(combinePerformance);
                newPerformance = performance;
            }
        }

        return { newChartData: newChartData, newPerformance: newPerformance, newStandardised: newStandardised };

    }

    processChartData(combinePerformance) {
        const { performance, chartData } = combinePerformance;
        let newChartData = [];
        if (chartData && performance) {
            let chartLength = 0;
            if (performance && performance.hasOwnProperty('dataMap') && performance.dataMap && performance.dataMap.length > 0) {
                chartLength = performance.dataMap.length;
            }
            newChartData = Object.assign(chartData, []);
            newChartData.forEach((chart) => {
                if (chart.hasOwnProperty('fundProxyDate')) {
                    chart.chartLength = chartLength;
                }
            })
        }
        return newChartData;
    }

    checkPerformancehasData(performance) {
        let value  = performance && performance.hasOwnProperty('dataMap') && performance.dataMap && performance.dataMap.length > 0
        return value
    };

    checkStandarisedhasData(standarised) {
        let value = standarised && standarised.hasOwnProperty('dataMap') && standarised.dataMap && standarised.dataMap.length > 0 && !standarised.columnKeyArray.includes("no_data_key")
            return value;
       
    };

    checkGraphData(graphData) {
        if (graphData && graphData.length > 0) {
            return graphData.some((graph) => {
                if (graph && graph.chartData && graph.chartData.length > 0 && graph.chartData !== '[]') {
                    return true;
                }
            });
        }
    }

    verifyIfInOneYearPerid(chartData) {
        let liveDate = 0;
        let asOfDate = 0;
        let isInOneYear = false;
        for (let i = 0; i < chartData.length; i++) {
            if (chartData[i].hasOwnProperty('startDate') && chartData[i].hasOwnProperty('endDate')) {
                liveDate = chartData[i].startDate;
                asOfDate = chartData[i].endDate;
            }
        }
        let timeDiffer = this.addDateForLeapYear(asOfDate) - this.addDateForLeapYear(liveDate);
        const yearDiffer = 31536000000;
        if (timeDiffer < yearDiffer && timeDiffer !== 0) {
            isInOneYear = true;
        }
        return isInOneYear;
    }

    addDateForLeapYear(dateStamp) {
        let newDateStamp = new Date(dateStamp);
        const thisDate = new Date(dateStamp);
        const getYear = thisDate.getUTCFullYear();
        if ((getYear % 100 === 0 && getYear % 400 === 0) || (getYear % 100 !== 0 && getYear % 4 === 0)) {
            const oneDate = 86400000;
            newDateStamp = thisDate + oneDate;
        }
        return newDateStamp;
    }

    getPdfUrl() {
        let location = window.location
        const { regionId, investorTypeId } = Utils.getUserInfo();
        const { mqaTicker } = this.props;
        let Url = getCisUrl() + '/cis-pub-service/v2/performance/download-graph/' + mqaTicker + '?investorTypeId=' + investorTypeId + '&regionId=' + regionId;
        let port = location.port;
        port = port ? (':' + port) : port;
        //hard code
        return Url
    }
}

export default Overview;



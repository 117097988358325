import moment from 'moment';
import 'moment-timezone';

class DateUtils{
    static formatDate(dateStr) {
        if (dateStr) {
            const year = dateStr.substring(0, 4);
            const month = parseInt(dateStr.substring(5, 7), 10);
            const day = dateStr.substring(8, 10) ? (" " + parseInt(dateStr.substring(8, 10), 10)):"";
            let monthStr = '';
            switch(month) {
                case 1: monthStr = 'January'; break;
                case 2: monthStr = 'February'; break;
                case 3: monthStr = 'March'; break;
                case 4: monthStr = 'April'; break;
                case 5: monthStr = 'May'; break;
                case 6: monthStr = 'June'; break;
                case 7: monthStr = 'July'; break;
                case 8: monthStr = 'August'; break;
                case 9: monthStr = 'September'; break;
                case 10: monthStr = 'October'; break;
                case 11: monthStr = 'November'; break;
                case 12: monthStr = 'December'; break;
                default: monthStr = 'unknow month';
            }
            return `${monthStr}${day}, ${year}`;
        }
        return '';
    };

//dd-mm-yy
    static formatDateCis(dateStr) {
        if (dateStr) {
            const year = dateStr.substring(2, 4);
            const month = parseInt(dateStr.substring(5, 7), 10);
            const day = dateStr.substring(8, 10);
            let monthStr = '';
            switch(month) {
                case 1: monthStr = 'Jan'; break;
                case 2: monthStr = 'Feb'; break;
                case 3: monthStr = 'Mar'; break;
                case 4: monthStr = 'Apr'; break;
                case 5: monthStr = 'May'; break;
                case 6: monthStr = 'Jun'; break;
                case 7: monthStr = 'Jul'; break;
                case 8: monthStr = 'Aug'; break;
                case 9: monthStr = 'Sep'; break;
                case 10: monthStr = 'Oct'; break;
                case 11: monthStr = 'Nov'; break;
                case 12: monthStr = 'Dec'; break;
                default: monthStr = 'unknow month';
            }
            return `${day}-${monthStr}-${year}`;
        }
        return '';
    };

    static formatDatePrint(dateStr) {
        if (dateStr) {
            const year = dateStr.substring(0, 4);
            const month = parseInt(dateStr.substring(5, 7), 10);
            const day = dateStr.substring(8, 10);
            let monthStr = '';
            switch(month) {
                case 1: monthStr = 'January'; break;
                case 2: monthStr = 'February'; break;
                case 3: monthStr = 'March'; break;
                case 4: monthStr = 'April'; break;
                case 5: monthStr = 'May'; break;
                case 6: monthStr = 'June'; break;
                case 7: monthStr = 'July'; break;
                case 8: monthStr = 'August'; break;
                case 9: monthStr = 'September'; break;
                case 10: monthStr = 'October'; break;
                case 11: monthStr = 'November'; break;
                case 12: monthStr = 'December'; break;
                default: monthStr = 'unknow month';
            }
            return `${day} ${monthStr} ${year}`;
        }
        return '';
    };

    static formaTime(timeStr){
        if (timeStr) {
            let hour = timeStr.substring(11,13);
            const halfDate = hour < 12 ? 'AM' : 'PM'
            hour = hour < 12 ? hour : hour-12 ;
            const minute = timeStr.substring(14,16);      
            return `${hour}:${minute} ${halfDate}`;
        }
        
    }
    
    static getTimeZone() {
        return moment.tz.guess();
    }
}

export default DateUtils;
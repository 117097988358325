import React from 'react';
import Utils from '../cisCommon/utils'
import PropTypes from 'prop-types';
import HtmlFormatter from '../cisCommon/htmlFormatter'
import RetailLink from '../commons/retailLink/RetailLink'

class IndicesDetailLinks extends React.Component {
    render() {
        const {maxHeight} = this.props;
        return (
            <div>
                <div className='cis-page-title'>Related News and Announcements</div>
                <div className='cis-indices-realted' style={{height: maxHeight * 20}}>
                    {this.renderLinkList()}
                </div>
            </div>
        );
    }

    renderLinkList() {
        const {regionId, investorTypeId} = Utils.getUserInfo();
        if (this.props.linkLists && this.props.linkLists.length > 0) {
            return (
                this.props.linkLists.map((links, index) => {

                    return (
                        <div key={index} className='h20'>
                            <div className='cis-realted-div w60'>{links.date}</div>
                            <RetailLink className='cis-link-blue'
                                  menuNode={'news/' + links.id + '/' + regionId + '/' + investorTypeId}>
                                <div className='cis-realted-div indices-news-and-anno-item'
                                     title={links.title.replace(/<su(p|b)>/g, '').replace(/<\/su(p|b)>/g, '')}>
                                    <HtmlFormatter value={links.title} title={links.title.replace(/<su(p|b)>/g, '').replace(/<\/su(p|b)>/g, '')} />
                                </div>
                            </RetailLink>
                        </div>
                    );
                })
            );
        }
        else {
            return (
                <div className='tc'>No Data</div>
            );
        }
    }
}

IndicesDetailLinks.defaultProps = {
    linkLists: [],
    maxHeight: 0
};

IndicesDetailLinks.propTypes = {
    linkLists: PropTypes.array.isRequired,
    maxHeight: PropTypes.number.isRequired
};

export default IndicesDetailLinks;
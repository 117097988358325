
const portletList = {
}


const repo = window['citicms-repo'],
repoKeys = repo && Object.keys(repo)

repoKeys && repoKeys.forEach(key=>{
    Object.defineProperty(portletList,key,{
        value:repo[key]
    })
})

export default portletList
import base64 from 'base-64';
import utf8 from 'utf8';

const DEV = 'DEV';
const QA = 'QA';
const UAT = 'UAT';
const PROD = 'PROD';

const ENV = 'ENV';
const HOST = 'HOST';

const { env_config:{siteId} } = window


const HOST_ENVS =[
                    {ENV: DEV, HOST:'localhost'}, {ENV: DEV, HOST:'127.0.0.1'}, // for local testing
                    {ENV: DEV, HOST:'sd-b12d-5762.nam.nsroot.net'},
                    {ENV: DEV, HOST:'dev.solutions.citi.net'},
                    {ENV: QA, HOST:'sd-59b3-ad34.nam.nsroot.net'},
                    {ENV: QA, HOST:'qa.solutions.citi.net'}
                ];
const PORTAL_URLS = [{ENV: DEV, HOST: 'dev.solutions.citi.net'}, {ENV: QA, HOST: 'qa.solutions.citi.net'}];
const API_URLS = [  {'type': 'image', 'api': `/rcs/v1/media/${siteId}/akpublic/storage/retrieveMediaFile/`},
                    {'type': 'page', 'api': '/retail/go/PAGES/'}
                ];

export const b64 = (rawValue) => {
    let bytes = utf8.encode(rawValue);
    let encodedValue = base64.encode(bytes);
    return encodedValue;
}

export const d64 = (encodedValue) => {
    let bytes = base64.decode(encodedValue);
    let rawValue= utf8.decode(bytes);
    
    return rawValue;
}

/**
 * type of source: image/content/page
 * ## samples:
 * http://dev.solutions.citi.net/pages/Insight_Detail_Final
*/
export  const getUrl = (type,param) => {
    let browserHost = getBrowserHost();
    let apiUrl = getAPIUrl(type,param);

    if (isPortalURL(browserHost)) {
        return apiUrl;
    }

    let host = getPortalURL(getEnv(browserHost));

    return `https://${host}${apiUrl}`;
}

const getBrowserHost = () => {
    let winUrl = window.location.href;
    let browserHost = winUrl.split('//')[1].split('/')[0];
    if (browserHost && browserHost.indexOf(':')>=0) {
        browserHost = browserHost.split(':')[0];
    }
    return browserHost;
}

const isPortalURL = (browserHost) => {
    return PORTAL_URLS.filter(u=>u[HOST] === browserHost).length > 0;
}

const getAPIUrl = (type, param) => {
    let api = '';
    let src = API_URLS.filter(u=>u.type === type);
    if (src.length > 0)  {
        api = src[0].api;
    }
        
    if ('page' === type) {
        var detailUrl = `__NAVIGATION_BASE64__pcs/PAGES/${param||''}`;
        let bytes = utf8.encode(detailUrl);
        let encodedValue = base64.encode(bytes);
        return api+encodedValue;
    }
    return api;
}

const getEnv = (browserHost) => {
    let env = HOST_ENVS.filter(h=>h[HOST] === browserHost);
    if (env.length > 0) return env[0][ENV];
    return '';
}

const getPortalURL = (env) => {
    let host = '';

    let url = PORTAL_URLS.filter(u=>u[ENV] === env);
    if (url.length > 0) {
        host = url[0][HOST];
    };

    return host;
}

export const getQueryUrl = (variable) =>{
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    let val = '';
    vars.forEach(item => {
        const pair = item.split("=");
        if(pair[0] == variable){ 
            val = pair[1];
        }
    })
    return val;
 }
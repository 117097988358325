import React, { useReducer, Component } from 'react'
import Overview from './indicesDetailOverview'
import { getNewsAnnouncements, getPerformance } from '../../services/cis';
import { checkisFetching } from '../../components/cisIndices/actions';

/**
 * Actions
 */
const receiveIndicesDetailLinkList = (json) => ({
    type: 'REVEIVE_INDICES_DETAIL_LINKLIST',
    linkLists: json.list ? json.list : []
});

const receiveIndicesDetailCombineInformation = (json, isFetching) => ({
    type: 'RECEIVE_INDICES_COMBINE',
    performanceStatistic: (json && json.performanceStatistic) ? json.performanceStatistic : { columnKeyArray: [], columnTitleArray: [], dataMap: [] },
    chartData: (json && json.performanceGraph) ? json.performanceGraph : [],
    performancePast: (json && json.performancePast) ? json.performancePast : { columnTitleArray: [], columnKeyArray: [], dataMap: [] },
    performanceParameters: (json && json.performanceParameters) ? json.performanceParameters : {
        hasStandardisedPerf: '',
        oneYearDataCheck: '',
        liveDataLine: ''
    },
    isFetching: isFetching,
})


/**
 * initial state
 */
const indicesDetailOvState = {
    isFetchIndicesDetailsOv: false,
    isFetchingCombineInformation: false,
    linkLists: [],
    combinePerformance: {
        performance: {columnKeyArray: [], columnTitleArray: [], dataMap: []},
        chartData: [],
        standardised: {columnKeyArray: [], columnTitleArray: [], dataMap: []},
        indicesStatus: {hasStandardisedPerf: false, oneYearDataCheck: true},
    }
};

/**
 * reducers
 * @param {*} state 
 * @param {*} action 
 */
const indicesDetailOV = (state = indicesDetailOvState, action) => {

    switch (action.type) {

        case 'REVEIVE_INDICES_DETAIL_LINKLIST':
            return {
                ...state,
                linkLists: action.linkLists
            };
        case 'CHECK_ISFETCHING_INDICES_COMBINE':
            return {
                ...state,
                combinePerformance: indicesDetailOvState.combinePerformance,
                isFetchingCombineInformation: action.isFetching,
            }
        case 'RECEIVE_INDICES_COMBINE':
            return {
                ...state,
                combinePerformance: {
                    performance: action.performanceStatistic,
                    chartData: action.chartData,
                    standardised: action.performancePast,
                    indicesStatus: action.performanceParameters
                },
                isFetchingCombineInformation: action.isFetching,
            };
        default:
            return state;
    }
};


const IndiceDetailOverviewContainer = (props) => {

    const [state,dispatch] = useReducer(indicesDetailOV,indicesDetailOvState)

    const actions = {
        fetchIndicesDetailLinkList:async (mqaTicker, regionId, investorId) =>{ 
            const rsp = await getNewsAnnouncements(mqaTicker,regionId,investorId)
            dispatch(receiveIndicesDetailLinkList(rsp))
        },
        fetchCombinedDetailInformation:async (mqaTicker, regionId, investorTypeId)=>{
            dispatch(checkisFetching('CHECK_ISFETCHING_INDICES_COMBINE', true))
            const rsp = await getPerformance(mqaTicker, regionId, investorTypeId)
            dispatch(receiveIndicesDetailCombineInformation(rsp,false))
        }
    }

  return (
    <div>
      <Overview 
        {...props}
        {...state} 
        dispatch={dispatch} 
        {...actions}
      />
    </div>
  )
}

export default IndiceDetailOverviewContainer

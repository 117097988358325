export default class Mask{
    static mask() {
        let spinner = document.getElementById('loading');
        spinner.style.display = 'block';
    }

    static unmask() {
        let spinner = document.getElementById('loading');
        spinner.style.display = 'none';
    }

    static toggle() {
        let spinner = document.getElementById('loading');
        let display = spinner.style.display;
        spinner.style.display = display === 'block' ? 'none' : 'block';
    }
}
import React from 'react'
import Utils from '../cisGrid/utils'
import LoadingMask from '../cisGrid/loadingmask'
import RetailLink from '../commons/retailLink/RetailLink'
import { getDisclaimer } from '../../services/cis';

export default class Disclaimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            warning: false,
            showMask: false
        };
    }


    getDisclaimer = async (regionId, userTypeId) => {
        this.setState({ showMask: true, warning: false })
        const rsp = await getDisclaimer(regionId, userTypeId)

        const currentYear = Utils.getCurrentYear();
        const replacedInform = (rsp && rsp.returningObject && rsp.returningObject.text) ? (rsp.returningObject.text.replace(/{copyrightEndYear}/g, currentYear)) : '';
        this.setState({ data: replacedInform, showMask: false });
    }


    transText() {
        return { __html: this.state.data };
    };

    notAgreeClick() {
        this.setState({ warning: true });
    };

    agreeClick() {
        const { region, userType, profileRelation } = this.props;
        const profileConfig = this.profileConfiguation(region, userType, profileRelation);
        let userInfo = { region: region, userType: userType, profileRelation: profileConfig[0] };
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    };

    profileConfiguation(region, userType, profileRelation) {
        let profileConfig = [];
        if (profileRelation) {
            for (let i = 0; i < profileRelation.length; i++) {
                if (Number(profileRelation[i].regionId) === region && Number(profileRelation[i].investorTypeId) === userType) {
                    profileConfig.push(profileRelation[i].cisIndexPerformanceConfigVO);
                }
            }
        }
        return profileConfig;
    }

    regionAndUserTypeValid(region, userType, profileRelation) {
        const profileConfig = this.profileConfiguation(region, userType, profileRelation);
        return (profileConfig && profileConfig.length > 0);
    }


    render() {
        const { show, region, userType, detailPathname, emailAutoPlayVideo, mqaTicker } = this.props;
        let { showMask } = this.state;
        const agreeMenuCode = detailPathname&&detailPathname[2]=== 'detail' ? `indice/detail/${detailPathname[3]}/${Number(detailPathname[4])}/${Number(detailPathname[5])}${emailAutoPlayVideo}` : `cis-home/${this.props.region}/${this.props.userType}`;
        const detailMenuCode = `indice/${mqaTicker}/${region}/${userType}`;
        const newMenuCode = mqaTicker ? detailMenuCode : agreeMenuCode;
        return (
            <div className={show ? 'mrg-b15' : 'disnone'}>
                <div className="pr">
                    <LoadingMask show={showMask}></LoadingMask>
                    <div className="cis-disclaimer p10 mrg15-0" dangerouslySetInnerHTML={this.transText()}></div>
                    <div className={this.state.warning ? 'cis-warning' : 'disnone'}>
                        Thank you for your interest. Without acceptance of the Terms and Conditions we are unable to
                        provide entry to the site.
                    </div>
                    <div className="by-center">
                        <span onClick={this.agreeClick.bind(this)}>
                            <RetailLink
                                menuNode={newMenuCode}
                            >
                                <button
                                    className="by-button by-xl by-bluefill by-uppercase orange"
                                >
                                    Agree
                                </button>
                            </RetailLink>
                        </span>                     
                        <button className="by-button by-xl by-edit by-uppercase"
                            onClick={this.notAgreeClick.bind(this)}>Don't Agree
                        </button>

                    </div>
                </div>
            </div>
        );
    }
};

import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import IndiceDetailOverviewContainer from './IndiceDetailOverviewContainer'
import Contact from '../cisLogin/contact'
import Utils from '../cisCommon/utils'
import HtmlFormatter from '../cisCommon/htmlFormatter'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { getQueryUrl } from '../../utils/URLUtils'
import IndiceCompositionContainer from './IndiceCompositionContainer'
import IndiceMoreDataContainer from './IndiceMoreDataContainer'
import CisIndicesBanner from '../../pages/indiceDetail/cisIndicesBanner';
import { fetchIndicesDetailCP } from './actions'
import "react-tabs/style/react-tabs.css";
import { getExposure } from '../../services/cis';

const { GetAccess } = window['citicms-repo']

const config = {
    title: "More Information",
    description: "Financial Intermediaries and Wealth Management Professionals can find more information about Citi's range of products and services",
    actionText: "Learn More",
    url: "https://www.crossassetsolutions.citi.com/",
    isSmallButton: true
}
class IndicesDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            exposure: {
                exposureList: null,
                exposurePlain: null
            }
        }
    }

    componentWillMount = async () => {

        console.log(this.props, "Detail")
        const { index: mqaTicker, regionId, investorTypeId } = this.props.match.params

        this.props.fetchIndicesDetailOV(mqaTicker, Number(regionId), Number(investorTypeId), this.props.dispatch)
        sessionStorage.setItem('detailIndicePathname', window.location.pathname)
        sessionStorage.setItem('emailAutoPlayVideo', window.location.search)
        sessionStorage.setItem('isEmailDetail', "false")
        const urlPrams = getQueryUrl('from')
        Utils.verifyRegionAndInvestorType(regionId, investorTypeId, urlPrams, mqaTicker);

        // const {  fetchIndicesDetailCP } = this.props;
        // fetchIndicesDetailCP(mqaTicker, regionId, investorTypeId);
        const rsp = await getExposure(mqaTicker)
        if (rsp && rsp.exposurePlain && rsp.exposureList) {
            this.setState(
                { exposure: rsp }
            )
        }

    }

    onTabClick = (name) => {
        const { trackPageEvent } = window.RETAIL;
        const { region, userType } = Utils.getInfoName();
        try {
            trackPageEvent(null, 'SwitchTab', `SwitchIn/${window.location.pathname.split('/')[1]}${region}${userType}`, `SwitchTo${name}`)
        } catch (err) {

        }
    }

    render() {
        const { strategyName, accordionData, attributeData, disclaimer, documentData, footNote, liveDate, showComposition, showTop10Composition } = this.props
        const { index: mqaTicker } = this.props.match.params
        const { exposure } = this.state
        const { exposureList, exposurePlain } = exposure
        let exposureHasData =
            exposure &&
            exposureList &&
            exposureList.length > 1 &&
            JSON.stringify(exposurePlain) !== '{}';
        return (
            <div>
                {/* <CisIndicesBanner mqaTicker={mqaTicker}/> */}
                <div className="cis-page-title">
                    <HtmlFormatter value={strategyName} />
                </div>
                <div className="cis-tab">
                    <Tabs>
                        <TabList>
                            <Tab
                                onClick={(_) => { this.onTabClick('Overview') }}
                            >Overview</Tab>
                            {
                                showComposition && <Tab
                                    onClick={(_) => { this.onTabClick('Composition') }}
                                >Composition</Tab>
                            }

                            {exposureHasData &&
                                <Tab
                                    onClick={(_) => { this.onTabClick('More Data') }}
                                >More Data</Tab>}
                        </TabList>
                        <TabPanel>
                            <IndiceDetailOverviewContainer
                                mqaTicker={mqaTicker}
                                disclaimer={disclaimer}
                                footNote={footNote}
                                documentData={documentData}
                                accordionData={accordionData}
                                attributeData={attributeData}
                                liveDate={liveDate}
                            />
                        </TabPanel>
                        {
                            showComposition &&
                            <TabPanel>
                                <IndiceCompositionContainer mqaTicker={mqaTicker} showTop10Composition={showTop10Composition}/>
                            </TabPanel>
                        }
                        {
                            exposureHasData &&
                            <TabPanel>
                                <IndiceMoreDataContainer exposure={exposure} mqaTicker={mqaTicker} />
                            </TabPanel>
                        }
                    </Tabs>
                </div>
                <hr></hr>

                <GetAccess {...config} />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state.indicesReducer
    }
}
const mapDispatchToProps = {
    fetchIndicesDetailCP
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndicesDetail))
import React, { Component } from 'react';

import GirdRow from './GridRow';
import GridCol from './GridCol';
import './Grid.css'

export default class Grid extends Component {

    render() {
        const { children } = this.props;
        return (
            <div className="Grid">
                {children}
            </div>
        );
    }
}

Grid.Row = GirdRow;
Grid.Col = GridCol;
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Loading.css'

export default class Loading extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
 

    componentDidMount() {

    }

    render() {
        const { color } = this.props;
        const style = { 
            backgroundColor: color || '#333',
         }

        return (
            <div className="Loading">
                <div className="spinner">
                    <div className="bounce1" style={style}></div>
                    <div className="bounce2" style={style}></div>
                    <div className="bounce3" style={style}></div>
                </div>
            </div>
        );
    }
}
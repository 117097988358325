import axios from 'axios'
import Utils from '../components/cisCommon/utils';
import { getCisUrl } from '../utils/Request';

const baseUrl = getCisUrl();
const cisApi = axios.create({
    headers:{
        'Cache-Control': 'no-cache',
        'X-XSS-Protection':'1',
        'If-Modified-Since': '0' ,
        'Content-Security-Policy':"default-src 'self' https: ws:;script-src 'self' https: 'unsafe-inline' 'unsafe-eval'; object-src 'none'; style-src 'self' https: 'unsafe-inline'",
        'X-Content-Type-Options':'nosniff'
    },
    baseURL:`${baseUrl}/citicis`
})

cisApi.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return response.data
    } else {
        return Promise.reject(response.data)
    }
}, function (err) {
    return Promise.reject(err)
})

export const getContact = () => {
    return cisApi.get('/cis/ui-service/v1/contact/getByRegionAndUserType?regionId=-1&investorTypeId=-1')
}

export const getCvUserProfile = (regionId, investorTypeId) => {
    return cisApi.get(`/cis/ui-service/v1/cv-user-profile?regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

export const getAboutCis = (regionId, investorTypeId) => {
    return cisApi.get(`/cis/ui-service/v1/aboutCis/page/?regionId=${regionId}&investorTypeId=${investorTypeId}`);
}

const newCisApi = axios.create({
    headers:{
        'Cache-Control': 'no-cache',
        'X-XSS-Protection':'1',
        'If-Modified-Since': '0' ,
        'Content-Security-Policy':"default-src 'self' https: ws:;script-src 'self' https: 'unsafe-inline' 'unsafe-eval'; object-src 'none'; style-src 'self' https: 'unsafe-inline'",
        'X-Content-Type-Options':'nosniff'
    },
    baseURL:`${baseUrl}/cis-pub-service/v1`
})

newCisApi.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return response.data
    } else {
        return Promise.reject(response.data)
    }
}, function (err) {
    return Promise.reject(err)
})

export const getDisclaimer = (regionId, investorTypeId) => {
    return newCisApi.get('/disclaimer/code/HOME_TERMS_CONDITIONS?regionId=2&investorTypeId=2')
}

export const getListByInvestorType = () => {
    return newCisApi.get('/region-and-investor_type/list')
}

export const getNewsAnnouncements = (mqaTicker, regionId, investorTypeId) => {
    return newCisApiV2.get(`/cis-strategy/related/news-annnouncements?mqaTicker=${mqaTicker}&regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

export const getNewsAnnoucementsList = (query) =>{
    const {startIndex,limit,article,type,regionId,investorTypeId} =query;
    try{
        const {region,userType} = Utils.getInfoName();
        window.RETAIL.trackPageEvent(null,'Search',`IndicesSearch/${window.location.pathname.split('/')[1]}${region}${userType}`,`article:${query.article?query.article:''}`)
    }catch(err){}
    return newCisApi.get(`/announcement/list?startIndex=${startIndex || 0}&limit=${limit || 10}&article=${article || ``}&type=${type || ``}&regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

export const getNewsDetail = (newsId, regionId, investorTypeId) => {
    return newCisApi.get(`/announcement/id/${newsId}?regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

//CIS-DOC PROJECT
export const getAcceptDisclaimerAsync = (distributionKey) => {
    return newCisApi.get(`/distribution/accept-disclaimer?distributionKey=${distributionKey}`);
}

export const getDeclineDisclaimerAsync = (distributionKey, disclaimerKey, emailAddress) => {
    return newCisApi.get(`/distribution/decline-desclaimer?distributionKey=${distributionKey}&disclaimerKey=${disclaimerKey}&emailAddress=${emailAddress}`)
}

export const getStrategyPageAsync = (distributionKey, disclaimerKey, emailAddress) => {
    return newCisApi.get(`/distribution/strategy-page?distributionKey=${distributionKey}&disclaimerKey=${disclaimerKey}&emailAddress=${emailAddress}`)
}

export const getDocumentKeyAsync = (stringfiedData) => {
    return newCisApi.post("/documentImage/document-key/dynamic",stringfiedData)
}

export const getPageViewAsync = (imgData) => {
    return newCisApi.post("/documentImage/document/page-view", imgData)
}

export const getDisclaimerCacheAsync = (pageUrl) => {
    return newCisApi.get(`/disclaimer/code/${pageUrl}`)
}

const newCisApiV2 = axios.create({
    headers:{
        'Cache-Control': 'no-cache',
        'X-XSS-Protection':'1',
        'If-Modified-Since': '0' ,
        'Content-Security-Policy':"default-src 'self' https: ws:;script-src 'self' https: 'unsafe-inline' 'unsafe-eval'; object-src 'none'; style-src 'self' https: 'unsafe-inline'",
        'X-Content-Type-Options':'nosniff'
    },
    baseURL:`${baseUrl}/cis-pub-service/v2`
})

newCisApiV2.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return response.data
    } else {
        return Promise.reject(response.data)
    }
}, function (err) {
    return Promise.reject(err)
})

//found in https://uat.investmentstrategies.citi.com/indice/CIXBFU35/16/2
// https://uat.investmentstrategies.citi.com/cis-pub-service/v1/performance/combine/CIISRLUG?regionId=19&investorTypeId=2
export const getPerformance = (indiceId, regionId, investorTypeId) => {
    return newCisApiV2.get(`/performance/combine/${indiceId}?regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

// found in indices detail overview
//found in https://uat.investmentstrategies.citi.com/indice/CIXBFU35/16/2 EXPORT
// https://uat.investmentstrategies.citi.com/cis-pub-service/v1/performance/download-graph/CIISRLUG?investorTypeId=2&regionId=19

//found in https://uat.investmentstrategies.citi.com/indice/CIXBFU35/16/2 composition
// https://uat.investmentstrategies.citi.com/cis-pub-service/v1/composition/mqaticker/CIISRLUG/date?regionId=19&investorTypeId=2
export const getComposition = (mqaTicker, regionId, investorTypeId) => {
    return newCisApiV2.get(`/composition/mqaticker/${mqaTicker}/date?regionId=${regionId}&investorTypeId=${investorTypeId}`)
}
export const getExposure = (mqaTicker) => {
    return newCisApiV2.get(`/composition/exposure/${mqaTicker}`)
}

// https://uat.investmentstrategies.citi.com/cis-pub-service/v1/cis-strategy/list/monitor1W/desc?searchText=&regionId=19&investorTypeId=2

// https://uat.investmentstrategies.citi.com/cis-pub-service/v1/cis-strategy/list/order-by/monitor1W/desc?startIndex=0&limit=15&searchText=CIISRLUG%20&associatedMqaTickers=&assetClass=All_Asset_Class&strategyType=All_Strategy_Type&strategySubType=All_Strategy&regionId=19&investorTypeId=2
export const getHomeIndiceAsync = (homeOrderBy, homeSearchText, regionId, investorTypeId) => {
    return newCisApiV2.get(`/cis-strategy/list/order-by/${homeOrderBy}/desc?startIndex=0&limit=10&searchText=${homeSearchText}&regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

export const getIndicesGrid = (pagiParams, sortParam, orderParam, regionId, investorTypeId, more) => {
    const { trackPageEvent } = window.RETAIL;
    const { searchText, startIndex, associatedMqaTickers, assetClass, strategyType, strategySubType, limit } = pagiParams;
    const orderBy = sortParam || 'monitor1W';
    orderParam = orderParam || 'desc';
    try {        
        const {region,userType} = Utils.getInfoName();
        trackPageEvent(null, 'Search', `IndicesSearch/${window.location.pathname.split('/')[1]}${region}${userType}`, `${searchText};Filters:{assetClass:${assetClass},strategyType:${strategyType},strategySubType:${strategySubType}}`)
    } catch (err) {

    }
    return newCisApiV2.get(`/cis-strategy/list/order-by/${orderBy}/${orderParam}?startIndex=${startIndex}&limit=${limit}&searchText=${searchText}&associatedMqaTickers=${associatedMqaTickers}&assetClass=${assetClass}&strategyType=${strategyType}&strategySubType=${strategySubType}&regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

// https://uat.investmentstrategies.citi.com/cis-pub-service/v1/cis-strategy/details/CIISRLUG?regionId=19&investorTypeId=2
export const getStrategyDetail = (indiceId, regionId, investorTypeId) => {
    return newCisApiV2.get(`/cis-strategy/details/${indiceId}?regionId=${regionId}&investorTypeId=${investorTypeId}`)
}

import React, { Component } from 'react';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if(this.props.location.pathname === prevProps.location.pathname && this.props.location.pathname  === '/your-partners' ) return
        if (this.props.location !== prevProps.location) {
           setTimeout(()=>{
            window.scrollTo(0, 0);
           },16)
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
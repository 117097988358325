import React,{useReducer} from 'react'
import Composition from './indicesDetailComposition'
import { checkisFetching } from './actions';
import { getComposition } from '../../services/cis';


const receiveIndicesDetailCP = (json, isFetching) => ({
    type: 'RECEIVE_INDICES_DETAIL_CP',
    allData: json.list ? json.list : [],
    isFetching: isFetching,
    asOfDate: json.asOfDate,
    sectorMap:json.sectorMap,
    countryMap:json.countryMap,
    exposure:json.exposure
});

const indicesCPState = {
    isFetchingIndicesComposition: false,
    sectorMap:null,
    countryMap:null,
    exposure:{
        exposureList:null,
        exposurePlain:null
    }
};
const indicesDetailCP = (state = indicesCPState, action) => {
    switch (action.type) {
        case 'RECEIVE_INDICES_DETAIL_CP':
            return {
                ...state,
                compData: action.allData,
                isFetchingIndicesComposition: action.isFetching,
                asOfDate: action.asOfDate,
                sectorMap:action.sectorMap,
                countryMap:action.countryMap,
                exposure:action.exposure
            };
        case 'CHECK_ISFETCHING_INDICES_COMPOSITION':
            return {
                ...state,
                compData: [],
                isFetchingIndicesComposition: action.isFetching,
                asOfDate: action.asOfDate
            };
        default:
            return state;
    }
};

const fetchIndicesDetailCP = async (mqaTicker, regionId, investorTypeId,dispatch) => {
    dispatch(checkisFetching('CHECK_ISFETCHING_INDICES_COMPOSITION', true))
    const rsp = await getComposition(mqaTicker, regionId, investorTypeId)
    dispatch(receiveIndicesDetailCP(rsp, false))
}

const IndiceCompositionContainer = (props) => {

    const [state,dispatch] = useReducer(indicesDetailCP,indicesCPState)

  return (
    <Composition {...state} {...props} dispatch={dispatch} fetchIndicesDetailCP={fetchIndicesDetailCP}/>
  )
}

export default IndiceCompositionContainer

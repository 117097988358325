import axios from 'axios';

import store from '../store/store';
import Mask from './Mask';
import { onServerError } from '../actions/commons/commonsActions';

export const getCisUrl = () => {
    const cisUrls = window.env_config.cisUrl.replace(/ /g, '').split(',');
    const origin = window.location.origin;
    return cisUrls.includes(origin) ? origin : cisUrls[0];
}

export const getRcsUrl = () => {
    const rcsUrls = window.env_config.baseUrl.replace(/ /g, '').split(',');
    const origin = window.location.origin;
    return rcsUrls.includes(origin) ? origin : rcsUrls[0];
}

let baseURL = getRcsUrl();

// baseURL = `http://10.114.188.150:8084/`;

const request = axios.create({
    baseURL,
    headers: { 
        'Cache-Control': 'no-cache',
        'If-Modified-Since': '0' ,
        'X-XSS-Protection':'1',
        'Content-Security-Policy':"default-src 'self' https: ws:;script-src 'self' https: 'unsafe-inline' 'unsafe-eval'; object-src 'none'; style-src 'self' https: 'unsafe-inline'",
        'X-Content-Type-Options':'nosniff'
    }
});

const checkUrl = (url) => {
    if (url.indexOf(baseURL) !== -1) {
        const regExp = new RegExp(`${baseURL}`, 'gi');
        url = url.replace(regExp, '');
    }
    return url;
}

let withoutBaseCount = 0;
let requstCount = 0;

export const citiRequestWithoutBase = axios.create({
    // baseURL: baseURL,
    headers: { 
        'Cache-Control': 'no-cache',
        'If-Modified-Since': '0' ,
        'X-XSS-Protection':'1',
        'Content-Security-Policy':"default-src 'self' https: ws:;script-src 'self' https: 'unsafe-inline' 'unsafe-eval'; object-src 'none'; style-src 'self' https: 'unsafe-inline'",
        'X-Content-Type-Options':'nosniff'
    }
});


const addCountInterceptorWithoutBase = citiRequestWithoutBase.interceptors.request.use(
    function (config) {
        if(config.hideLoading !== true){
            withoutBaseCount++;
            if (withoutBaseCount === 1) Mask.mask();
        }    
        return config;
    }, function (err) {
        return Promise.reject(err)
    })

citiRequestWithoutBase.interceptors.response.use(
    function (response) {
       if(response.config.hideLoading !== true){
        withoutBaseCount--;
        if (withoutBaseCount === 0) Mask.unmask();
       }
        return response.data
    }, function (err) {
        return Promise.reject(err)
    })

export const citiRequest = axios.create({
    baseURL: baseURL,
    headers: { 
        'Cache-Control': 'no-cache',
        'If-Modified-Since': '0' ,
        'X-XSS-Protection':'1',
        'Content-Security-Policy':"default-src 'self' https: ws:;script-src 'self' https: 'unsafe-inline' 'unsafe-eval'; object-src 'none'; style-src 'self' https: 'unsafe-inline'",
        'X-Content-Type-Options':'nosniff'
    }
});

const addCountInterceptor = citiRequest.interceptors.request.use(
    function (config) {

       // alert("Config",JSON.stringify(config))
       if(config.hideLoading !== true){
        requstCount++;
        if (requstCount === 1) Mask.mask();
       }
        return config;
    }, function (err) {
        return Promise.reject(err)
    })

citiRequest.interceptors.response.use(
    function (response) {
       if(response.config.hideLoading !== true){
        requstCount--;
        if (requstCount === 0) Mask.unmask();
       }
       return response.data
    }, function (err) {
       // alert("Meet Error:",err)
        // window.location.replace('/maintenance');
        store.dispatch(onServerError(true));
        Mask.unmask();
        return Promise.reject(err)
    }
)
import React from 'react';
import PropTypes from 'prop-types';

export default class SvgGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.onSvgClick = this.onSvgClick.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        const { type, top, svgClass, show, svgId } = this.props;
        return nextProps.type !== type || nextProps.show !== show || nextProps.svgClass !== svgClass || nextProps.top !== top || nextProps.svgId !== svgId;
    }

    render() {
        const { type, top, svgSize, svgClass, show, svgId } = this.props;
        const generateHeight = this.generateBySvgHeight(svgSize);
        const generateWidth = this.generateBySvgWidth(type, generateHeight);
        const iconClass = `ib by-svg-icon ${svgClass} x-scope bc-icon-0`;
        const displayStatus = show ? 'inline-block' : 'none';
        let otherStyle = {};
        if (this.props.style && typeof this.props.style === 'object') {
            otherStyle = this.props.style;
        }
        let svgStyle = { top: top, height: generateHeight, width: generateWidth, display: displayStatus, ...otherStyle };
        return (
            <div ref={this.props.helpRef} className={iconClass} id={svgId}
                style={svgStyle}
                onClick={this.onSvgClick}>
                {this.renderSvg()}
            </div>
        );
    }

    renderSvg() {
        const { type } = this.props;
        const svgStyle = { pointerEvents: 'none', display: 'block', height: '100%' };
        if (type && type === 'by-icon:alert-buble') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' style={svgStyle} focusable='false'>
                    <g>
                        <path
                            d='M20,10c0,5.522-4.478,10-10,10S0,15.522,0,10C0,4.477,4.478,0,10,0S20,4.477,20,10z M11.389,3.394H8.595l0.565,8.657h1.663 L11.389,3.394z M10.943,13.782C10.68,13.52,10.366,13.388,10,13.388c-0.377,0-0.697,0.132-0.96,0.395 c-0.263,0.263-0.394,0.583-0.394,0.96s0.131,0.697,0.394,0.96c0.263,0.263,0.583,0.395,0.96,0.395c0.366,0,0.68-0.132,0.943-0.395 s0.394-0.583,0.394-0.96S11.206,14.045,10.943,13.782z'></path>
                    </g>
                </svg>
            );
        } else if (type && type === 'delete') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' style={svgStyle} focusable='false'>
                    <g>
                        <path
                            d='M10.015,20.001C4.477,20.001,0,15.518,0,10.001S4.477,0,10.015,0C15.523,0,20,4.484,20,10.001S15.523,20.001,10.015,20.001z M15.7,9.04H4.359v1.921H15.7V9.04z'></path>
                    </g>
                </svg>
            );
        } else if (type && type === 'by-icon:triangle-n') {
            return (
                <svg focusable='false' tabIndex='-1' viewBox='0 0 21 20' preserveAspectRatio='xMidYMid meet'
                    className='style-scope bc-icon' style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path d='M0,20L10.007,0l10.007,20H0z' className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:triangle-s') {
            return (
                <svg focusable='false' tabIndex='-1' viewBox='0 0 21 20' preserveAspectRatio='xMidYMid meet'
                    className='style-scope bc-icon' style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path d='M20.014,0L10.007,20L0,0H20.014z' className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:profile') {
            return (
                <svg focusable='false' tabIndex='-1' viewBox='0 0 21 20' preserveAspectRatio='xMidYMid meet'
                    className='style-scope bc-icon' style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g id='Layer_1' className='style-scope bc-icon'></g>
                        <g id='Layer_4' className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <path
                                    d='M16.396,20l-1.58-8.173l-4.518-1.666L10.052,9c0,0,0.637-0.823,0.817-1.3c0.183-0.477,0.5-1.387,0.5-1.387l0.191-0.156l0.24-1.433l-0.295-0.136c0,0,0.328-1.773-0.373-2.953C10.734,0.966,9.532,0,8.199,0C6.866,0,5.578,0.923,5.266,1.636C5.097,2.021,4.758,2.93,4.706,3.613c0.198,0.347,0.187,0.976,0.187,0.976L4.597,4.725l0.241,1.433l0.19,0.156c0,0,0.318,0.91,0.5,1.387C5.708,8.177,6.345,9,6.345,9l-0.246,1.161l-4.518,1.666L0,20H16.396z'
                                    className='style-scope bc-icon'></path>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:chevron-thin-w') {
            return (
                <svg focusable='false' tabIndex='-1' viewBox='0 0 8 20' preserveAspectRatio='xMidYMid meet'
                    className='style-scope bc-icon' style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path d='M4.973,0h2.162l-5.06,10.011L7.048,20H4.886L0,10.163V9.837L4.973,0z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:chevron-thin-e') {
            return (
                <svg focusable='false' tabIndex='-1' viewBox='0 0 8 20' preserveAspectRatio='xMidYMid meet'
                    className='style-scope bc-icon' style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path d='M7.135,9.837v0.326L2.249,20H0.086l4.973-9.989L0,0h2.162L7.135,9.837z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:chevron-thin-s') {
            return (
                <svg viewBox='0 0 20 8' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path d='M10.163,7.135H9.837L0,2.249V0.087L9.989,5.06L20,0v2.162L10.163,7.135z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:chevron-thin-n') {
            return (
                <svg viewBox='0 0 20 8' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path d='M20,4.973v2.162L9.989,2.075L0,7.048V4.886L9.837,0h0.326L20,4.973z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:add-bubble') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path
                                d='M17.071,2.929c-3.906-3.905-10.237-3.905-14.143,0s-3.905,10.236,0,14.142c3.905,3.906,10.237,3.906,14.143,0C20.977,13.166,20.976,6.834,17.071,2.929z M9.329,16.635v-5.964L3.366,10.67l0.001-1.299h5.962V3.365h1.342l0.001,6.008l5.921-0.003l0.001,1.301l-5.922-0.001l-0.003,5.964L9.329,16.635z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'sort-trig-up') {
            return (
                <svg viewBox='0 0 40 70' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <polyline points='0,30  30,30  15,0 0,30' style={{ fill: '#00bdf2' }} />
                    <polyline points='0,40  30,40  15,70 0,40'
                        style={{ stroke: '#a9a9a9', strokeWidth: '3', fill: '#fff' }} />
                </svg>
            );
        }
        else if (type && type === 'sort-trig-down') {
            return (
                <svg viewBox='0 0 40 70' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <polyline points='0,30  30,30  15,0 0,30'
                        style={{ stroke: '#a9a9a9', strokeWidth: '3', fill: '#fff' }} />
                    <polyline points='0,40  30,40  15,70 0,40' style={{ fill: '#00bdf2' }} />
                </svg>
            );
        }
        else if (type && type === 'sort-trig-default') {
            return (
                <svg viewBox='0 0 40 70' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <polyline points='0,30  30,30  15,0 0,30'
                        style={{ stroke: '#a9a9a9', strokeWidth: '3', fill: '#fff' }} />
                    <polyline points='0,40  30,40  15,70 0,40'
                        style={{ stroke: '#a9a9a9', strokeWidth: '3', fill: '#fff' }} />
                </svg>
            );
        }
        else if (type && type === 'by-icon:search') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <path
                            d='M19.151,18.137l-5.174-5.174c1.143-1.371,1.831-3.134,1.831-5.059C15.809,3.539,12.271,0,7.904,0S0,3.539,0,7.904s3.538,7.904,7.904,7.904c1.487,0,2.873-0.418,4.061-1.133L17.288,20L19.151,18.137z M2.635,7.904c0-2.906,2.364-5.27,5.27-5.27s5.27,2.363,5.27,5.27s-2.364,5.27-5.27,5.27S2.635,10.811,2.635,7.904z'
                            className='style-scope bc-icon'></path>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:close-delete') {
            return (
                <svg viewBox='0 0 11 11' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <polygon
                            points='1.006,10.954 5.478,6.483 9.949,10.956 10.953,9.95 6.484,5.478 10.924,1.037 9.949,0.062 5.51,4.504 1.006,0 0,1.007 4.503,5.51 0.033,9.979 '
                            className='style-scope bc-icon'></polygon>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-logo:logo-citi-reverse-red') {
            return (
                <svg viewBox='0 0 35 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <g className='style-scope bc-icon'>
                                    <g className='style-scope bc-icon'>
                                        <path style={{ fill: '#FFF' }}
                                            d='M9.455,16.125L9.4,16.182c-0.894,0.914-1.93,1.396-2.998,1.396c-2.229,0-3.848-1.673-3.848-3.979c0-2.305,1.619-3.978,3.848-3.978c1.068,0,2.104,0.483,2.998,1.398l0.055,0.056l1.436-1.734l-0.038-0.045c-1.191-1.41-2.625-2.095-4.38-2.095c-1.765,0-3.379,0.591-4.545,1.665C0.667,10.026,0,11.663,0,13.6c0,1.938,0.667,3.574,1.927,4.736C3.093,19.409,4.707,20,6.472,20c1.756,0,3.189-0.686,4.38-2.096l0.038-0.045L9.455,16.125z'
                                            className='style-scope bc-icon'></path>
                                        <polygon style={{ fill: '#FFF' }}
                                            points='12.339,7.4 12.339,19.763 14.87,19.763 14.87,19.007 14.87,7.4'
                                            className='style-scope bc-icon'></polygon>
                                        <path style={{ fill: '#FFF' }}
                                            d='M24.794,17.075c-0.675,0.411-1.305,0.619-1.872,0.619c-0.82,0-1.186-0.431-1.186-1.396V9.741h2.575V7.413h-2.575V3.564l-2.484,1.331v2.518H17.11v2.328h2.142v6.977c0,1.902,1.128,3.201,2.808,3.233c1.143,0.021,1.829-0.313,2.246-0.568l0.024-0.015l0.611-2.384L24.794,17.075z'
                                            className='style-scope bc-icon'></path>
                                        <polygon style={{ fill: '#FFF' }}
                                            points='26.891,7.4 26.891,19.763 29.423,19.763 29.423,18.763 29.423,7.4'
                                            className='style-scope bc-icon'></polygon>
                                        <path style={{ fill: 'red' }}
                                            d='M31.017,5.4C28.904,2.234,25.004-0.001,20.838,0c-4.101,0.001-8,2.169-10.182,5.4h2.911c1.986-2.046,4.562-3.16,7.271-3.162c2.661-0.001,5.278,1.115,7.268,3.162H31.017z'
                                            className='style-scope bc-icon'></path>
                                    </g>
                                </g>
                            </g>
                            <path style={{ fill: '#FFF' }}
                                d='M34.729,4.703c0,0.633-0.482,1.116-1.116,1.116s-1.117-0.483-1.117-1.116c0-0.634,0.483-1.117,1.117-1.117S34.729,4.069,34.729,4.703z M34.943,4.703c0-0.755-0.575-1.33-1.33-1.33c-0.756,0-1.33,0.575-1.33,1.33c0,0.755,0.574,1.33,1.33,1.33C34.368,6.032,34.943,5.458,34.943,4.703z M34.221,4.39c0-0.303-0.217-0.439-0.475-0.439h-0.638v1.452h0.246v-0.56h0.28l0.284,0.56h0.291l-0.298-0.585C34.1,4.761,34.221,4.618,34.221,4.39z M33.971,4.397c0,0.144-0.1,0.206-0.232,0.206h-0.384V4.19h0.384C33.871,4.19,33.971,4.26,33.971,4.397z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );

        }
        else if (type && type === 'by-logo:logo-citi-2-color') {
            return (
                <svg viewBox='0 0 35 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <g className='style-scope bc-icon'>
                                    <g className='style-scope bc-icon'>
                                        <path style={{ fill: '#002D72' }}
                                            d='M9.454,16.125L9.4,16.182c-0.894,0.914-1.93,1.396-2.998,1.396c-2.23,0-3.848-1.673-3.848-3.979c0-2.305,1.618-3.978,3.848-3.978c1.067,0,2.104,0.483,2.998,1.398l0.054,0.056l1.436-1.734l-0.038-0.045c-1.191-1.41-2.625-2.095-4.38-2.095c-1.765,0-3.379,0.591-4.545,1.665C0.666,10.026,0,11.663,0,13.6c0,1.938,0.666,3.574,1.927,4.736C3.093,19.409,4.707,20,6.472,20c1.756,0,3.189-0.686,4.38-2.096l0.038-0.045L9.454,16.125z'
                                            className='style-scope bc-icon'></path>
                                        <polygon style={{ fill: '#002D72' }}
                                            points='12.339,7.4 12.339,19.763 14.87,19.763 14.87,18.763 14.87,7.4'
                                            className='style-scope bc-icon'></polygon>
                                        <path style={{ fill: '#002D72' }}
                                            d='M24.794,17.075c-0.675,0.411-1.305,0.619-1.872,0.619c-0.82,0-1.186-0.431-1.186-1.396V9.741h2.575V7.413h-2.575V3.564l-2.484,1.331v2.519H17.11v2.328h2.142v6.977c0,1.902,1.128,3.201,2.808,3.233c1.143,0.021,1.829-0.313,2.246-0.568l0.024-0.015l0.611-2.384L24.794,17.075z'
                                            className='style-scope bc-icon'></path>
                                        <polygon style={{ fill: '#002D72' }}
                                            points='26.891,7.4 26.891,19.763 29.423,19.763 29.423,18.763 29.423,7.4'
                                            className='style-scope bc-icon'></polygon>
                                        <path style={{ fill: 'red' }}
                                            d='M31.016,5.4C28.904,2.234,25.004-0.001,20.838,0c-4.101,0.001-8,2.169-10.182,5.4h2.911c1.986-2.046,4.561-3.16,7.271-3.162c2.661-0.001,5.278,1.115,7.268,3.162H31.016z'
                                            className='style-scope bc-icon'></path>
                                    </g>
                                </g>
                            </g>
                            <path style={{ fill: '#002D72' }}
                                d='M34.729,4.703c0,0.633-0.482,1.116-1.116,1.116s-1.117-0.483-1.117-1.116c0-0.634,0.483-1.117,1.117-1.117S34.729,4.069,34.729,4.703z M34.943,4.703c0-0.755-0.575-1.33-1.33-1.33c-0.756,0-1.331,0.575-1.331,1.33c0,0.755,0.575,1.33,1.331,1.33C34.368,6.032,34.943,5.458,34.943,4.703z M34.221,4.39c0-0.303-0.217-0.439-0.475-0.439h-0.638v1.452h0.246v-0.56h0.28l0.284,0.56h0.291l-0.299-0.585C34.1,4.761,34.221,4.618,34.221,4.39z M33.971,4.397c0,0.144-0.1,0.206-0.232,0.206h-0.384V4.19h0.384C33.871,4.19,33.971,4.26,33.971,4.397z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:spinner-donut') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path
                                d='M12.938,5.953l2.938-4.045C14.1,0.617,12.04-0.001,10,0v5.003C11.02,5.003,12.05,5.308,12.938,5.953z'
                                className='style-scope bc-icon'></path>
                            <path opacity='0.5'
                                d='M15.877,1.908l-2.938,4.045c2.234,1.624,2.729,4.75,1.106,6.984s-4.75,2.729-6.983,1.106c-2.234-1.623-2.729-4.75-1.106-6.984C6.933,5.714,8.456,5.005,10,5.003V0c-3.09,0.003-6.135,1.43-8.09,4.121c-3.246,4.468-2.256,10.722,2.212,13.968c4.468,3.247,10.721,2.256,13.967-2.212C21.336,11.408,20.345,5.154,15.877,1.908z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:close-delete-bubble') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path
                                d='M10,0C4.477,0,0,4.477,0,10s4.477,10,10,10s10-4.477,10-10S15.523,0,10,0z M14.218,15.166l-4.217-4.217l-4.217,4.215l-0.917-0.918l4.215-4.216L4.836,5.783l0.948-0.949l4.247,4.248l4.187-4.189l0.919,0.92L10.95,9.999l4.215,4.219L14.218,15.166z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-custom-color:file-pdf-red') {
            return (
                <svg viewBox='0 0 16 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g id='Layer_1' className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: 'red' }}
                                        d='M15.119,12.758c-0.736-0.541-1.76-0.768-2.648-0.902c-0.771-0.119-1.551-0.157-2.326-0.137c1.113,0.574,2.311,0.984,3.543,1.191c0.519,0.088,1.086,0.189,1.612,0.109c0.021-0.002,0.033-0.004,0.048-0.006c-0.008-0.005-0.014-0.013-0.018-0.021C15.285,12.9,15.202,12.818,15.119,12.758z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: 'red' }}
                                        d='M0.306,17.15c-0.087,0.174-0.261,0.49-0.212,0.699c0.075-0.041,0.148-0.086,0.219-0.136c1.314-0.914,2.433-2.358,3.186-3.868c-0.458,0.312-0.897,0.649-1.307,1.024C1.477,15.531,0.745,16.275,0.306,17.15z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: 'red' }}
                                        d='M3.955,12.809c0.085-0.225,0.163-0.447,0.23-0.67c0.352-1.156,0.577-2.322,0.641-3.49C4.291,7.982,3.824,7.26,3.441,6.475c-0.508-1.041-1.725-3.436-0.879-4.59C3.39,0.754,4.516,2.066,4.877,2.797c0.826,1.67,1.25,3.691,1.15,5.592c0.889,1.082,1.958,2.025,3.15,2.775c0.435-0.053,0.869-0.09,1.3-0.096c1.258-0.021,2.551,0.07,3.764,0.412c0.352,0.1,0.763,0.207,1.145,0.36V4.717H9.423V0H1.154C0.517,0,0,0.506,0,1.131v14.898C1.062,14.701,2.47,13.633,3.955,12.809z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: 'red' }}
                                        d='M5.857,9.781c-0.15,0.836-0.358,1.648-0.635,2.438c0.829-0.344,1.697-0.623,2.581-0.82C7.107,10.906,6.454,10.369,5.857,9.781z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: 'red' }}
                                        d='M3.502,2.67c0.008,0.182,0.03,0.363,0.062,0.545C3.772,4.361,4.182,5.463,4.74,6.488C4.671,5.967,4.572,5.447,4.422,4.924C4.213,4.195,3.965,3.326,3.502,2.67z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: 'red' }}
                                        d='M14.279,13.662c-1.979-0.113-4.059-0.764-5.768-1.799C7.27,12.059,6.058,12.441,4.92,13c-0.385,0.91-0.865,1.781-1.479,2.6c-0.82,1.094-1.99,2.582-3.441,2.902v0.365C0,19.492,0.517,20,1.154,20H14.23c0.637,0,1.153-0.508,1.153-1.133v-5.316C14.939,13.645,14.486,13.674,14.279,13.662z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <polygon style={{ fill: 'red' }} points='15.135,3.652 10.577,0.146 10.577,3.652'
                                        className='style-scope bc-icon'></polygon>
                                </g>
                            </g>
                        </g>
                        <g id='Layer_3' className='style-scope bc-icon'></g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-custom-color:file-excel-green') {
            return (
                <svg viewBox='0 0 16 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g id='Layer_1' className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: '#02BC18' }}
                                        d='M4.513,16.896C4.34,17.273,3.937,17.934,3.592,18.5L2.662,20h3.701l-0.93-1.501C5.068,17.914,4.705,17.271,4.513,16.896z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: '#02BC18' }}
                                        d='M0,10.288v8.58c0,0.114,0.022,0.226,0.056,0.329l2.941-4.416L0,10.288z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <polygon style={{ fill: '#02BC18' }} points='15.1,3.646 10.553,0.148 10.553,3.646'
                                        className='style-scope bc-icon'></polygon>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: '#02BC18' }}
                                        d='M9.4,4.717V0h-8.25C0.516,0,0,0.508,0,1.132v9.103h3.111l0.48,0.812c0.364,0.585,0.729,1.227,0.921,1.604c0.192-0.377,0.576-1.019,0.921-1.604l0.479-0.812h3.148l-3.032,4.547L9.504,20h4.694c0.636,0,1.151-0.507,1.151-1.132V4.797H9.4V4.717z'
                                        className='style-scope bc-icon'></path>
                                </g>
                            </g>
                        </g>
                        <g id='Layer_3' className='style-scope bc-icon'></g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-custom-color:file-word-doc-blue') {
            return (
                <svg viewBox='0 0 16 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g id='Layer_1' className='style-scope bc-icon'></g>
                        <g id='Layer_3' className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#289CFF' }}
                                        d='M0,18.867C0,19.493,0.517,20,1.154,20h0.646L0,13.861V18.867z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#289CFF' }}
                                        d='M5.597,13.934C5.52,14.405,5.25,15.5,5.058,16.272L4.099,20h2.995l-0.958-3.728C5.943,15.5,5.673,14.405,5.597,13.934z'
                                        className='style-scope bc-icon'></path>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <polygon style={{ fillrule: 'evenodd', clipRule: 'evenodd', fill: '#289CFF' }}
                                        points='15.135,3.654 10.577,0.148 10.577,3.654'
                                        className='style-scope bc-icon'></polygon>
                                </g>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#289CFF' }}
                                        d='M9.423,4.808V4.717V0H1.154C0.517,0,0,0.507,0,1.132v9.122h1.635l0.885,3.66C2.692,14.67,2.924,15.707,3,16.179c0.077-0.49,0.327-1.491,0.539-2.265l1-3.66h2.193l1,3.66c0.211,0.773,0.461,1.774,0.539,2.265c0.077-0.472,0.307-1.509,0.481-2.265l0.884-3.66h2.616L9.394,20h4.837c0.638,0,1.154-0.507,1.154-1.133V4.808H9.423z'
                                        className='style-scope bc-icon'></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-custom-color:document-generic-file-gray') {
            return (
                <svg viewBox='0 0 16 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <polygon style={{ fill: '#999' }} points='14.926,3.604 10.432,0.146 10.432,3.604'
                                    className='style-scope bc-icon'></polygon>
                            </g>
                            <g className='style-scope bc-icon'>
                                <g className='style-scope bc-icon'>
                                    <path style={{ fill: '#999' }}
                                        d='M9.293,4.717V0H1.138C0.51,0,0,0.507,0,1.132v17.736C0,19.493,0.51,20,1.138,20h12.896c0.629,0,1.138-0.507,1.138-1.132V4.717H9.293z M11.945,16.355H3.227c-0.314,0-0.569-0.254-0.569-0.569c0-0.313,0.255-0.568,0.569-0.568h8.718c0.314,0,0.57,0.255,0.57,0.568C12.516,16.102,12.26,16.355,11.945,16.355zM11.945,12.561H3.227c-0.314,0-0.569-0.254-0.569-0.569c0-0.313,0.255-0.568,0.569-0.568h8.718c0.314,0,0.57,0.255,0.57,0.568C12.516,12.307,12.26,12.561,11.945,12.561z M11.945,8.764H3.227c-0.314,0-0.569-0.254-0.569-0.569c0-0.314,0.255-0.569,0.569-0.569h8.718c0.314,0,0.57,0.255,0.57,0.569S12.26,8.764,11.945,8.764z'
                                        className='style-scope bc-icon'></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:tag') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <path
                            d='M0,9.127V1.291C0,0.581,0.581,0,1.291,0h7.836l10.496,10.496c0.502,0.502,0.502,1.325,0,1.827l-7.301,7.301c-0.502,0.502-1.325,0.502-1.827,0L0,9.127z M6.311,3.573c-0.756-0.756-1.98-0.756-2.736,0C2.818,4.33,2.817,5.555,3.573,6.311c0.757,0.757,1.983,0.757,2.74,0C7.069,5.555,7.068,4.33,6.311,3.573z'
                            className='style-scope bc-icon'></path>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:calendar-dots') {
            return (
                <svg viewBox='0 0 21 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <path
                            d='M18.876,2.886h-2.769V1.007C16.107,0.451,15.656,0,15.101,0v2.886v2.786c-0.556,0-1.007-0.451-1.007-1.006v-1.78H6.04V1.007C6.04,0.451,5.589,0,5.034,0v2.886v2.786c-0.556,0-1.007-0.451-1.007-1.006v-1.78H1.258C0.566,2.886,0,3.452,0,4.145v3.775h20.134V4.145C20.134,3.452,19.568,2.886,18.876,2.886z'
                            className='style-scope bc-icon'></path>
                        <g className='style-scope bc-icon'>
                            <circle cx='1.007' cy='9.933' r='1.007' className='style-scope bc-icon'></circle>
                        </g>
                        <g className='style-scope bc-icon'>
                            <circle cx='4.027' cy='9.933' r='1.007' className='style-scope bc-icon'></circle>
                        </g>
                        <circle cx='7.047' cy='9.933' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='10.067' cy='9.933' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='13.087' cy='9.933' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='16.107' cy='9.933' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='19.127' cy='9.933' r='1.006' className='style-scope bc-icon'></circle>
                        <circle cx='1.007' cy='12.953' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='4.027' cy='12.953' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='7.047' cy='12.953' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='10.067' cy='12.953' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='13.087' cy='12.953' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='16.107' cy='12.953' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='19.127' cy='12.953' r='1.006' className='style-scope bc-icon'></circle>
                        <circle cx='1.007' cy='15.974' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='4.027' cy='15.974' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='7.047' cy='15.974' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='10.067' cy='15.974' r='1.007' className='style-scope bc-icon'></circle>
                        <path
                            d='M13.087,15.467c0.279,0,0.507,0.228,0.507,0.507s-0.228,0.506-0.507,0.506s-0.507-0.227-0.507-0.506S12.808,15.467,13.087,15.467 M13.087,14.967c-0.556,0-1.007,0.45-1.007,1.007c0,0.556,0.451,1.006,1.007,1.006c0.557,0,1.007-0.45,1.007-1.006C14.094,15.417,13.644,14.967,13.087,14.967L13.087,14.967z'
                            className='style-scope bc-icon'></path>
                        <circle cx='16.107' cy='15.974' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='19.127' cy='15.974' r='1.006' className='style-scope bc-icon'></circle>
                        <circle cx='1.007' cy='18.993' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='4.027' cy='18.993' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='7.047' cy='18.993' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='10.067' cy='18.993' r='1.007' className='style-scope bc-icon'></circle>
                        <circle cx='13.087' cy='18.993' r='1.007' className='style-scope bc-icon'></circle>
                        <g className='style-scope bc-icon'>
                            <circle cx='16.107' cy='18.993' r='1.007' className='style-scope bc-icon'></circle>
                        </g>
                        <g className='style-scope bc-icon'>
                            <circle cx='19.127' cy='18.993' r='1.006' className='style-scope bc-icon'></circle>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:help-info-bubble') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path
                                d='M10,0C4.478,0,0,4.478,0,10s4.478,10,10,10s10-4.478,10-10S15.522,0,10,0z M9.745,16.132c-0.781,0-1.444-0.662-1.444-1.443s0.663-1.444,1.444-1.444s1.443,0.663,1.443,1.444S10.526,16.132,9.745,16.132z M12.734,10.136c-1.427,0.68-1.783,1.07-1.834,2.072H8.573c0-1.614,0.475-2.615,2.395-3.499c0.85-0.391,1.104-0.662,1.104-1.273c0-0.781-0.577-1.24-2.021-1.24c-1.291,0-2.208,0.612-2.973,1.427L5.55,5.839c1.036-1.088,2.514-1.971,4.501-1.971c2.599,0,4.399,1.104,4.399,3.465C14.45,8.913,13.55,9.745,12.734,10.136z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:delete-minus-bubble') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path
                                d='M10.015,20.001C4.477,20.001,0,15.518,0,10.001S4.477,0,10.015,0C15.523,0,20,4.484,20,10.001S15.523,20.001,10.015,20.001z M15.7,9.04H4.359v1.921H15.7V9.04z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:checkmark-bubble') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path
                                d='M10,0C4.477,0,0,4.477,0,10c0,5.522,4.477,10,10,10c5.522,0,10-4.478,10-10C20,4.477,15.522,0,10,0z M7.378,15.989l-4.302-4.302l1.704-1.726l2.598,2.607l7.771-7.772L16.866,6.5L7.378,15.989z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:alert-triangle') {
            return (
                <svg viewBox='0 0 23 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <path
                            d='M11.288,0C10.759,0,10.23,0.347,9.83,1.042L0.342,17.476C-0.46,18.863,0.196,20,1.8,20h18.976c1.604,0,2.26-1.137,1.458-2.524L12.745,1.042C12.345,0.347,11.816,0,11.288,0L11.288,0z M11.96,13.605h-1.358L10.14,6.54h2.281L11.96,13.605z M12.38,15.803c0,0.309-0.107,0.569-0.322,0.783c-0.215,0.215-0.471,0.322-0.77,0.322c-0.308,0-0.569-0.107-0.784-0.322c-0.215-0.214-0.322-0.475-0.322-0.783c0-0.308,0.107-0.568,0.322-0.783s0.476-0.322,0.784-0.322c0.299,0,0.555,0.107,0.77,0.322S12.38,15.495,12.38,15.803z'
                            className='style-scope bc-icon'></path>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:ban-exclude') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <path
                            d='M10,0C7.961,0,6.068,0.613,4.487,1.66C3.365,2.402,2.402,3.366,1.659,4.488C0.612,6.069,0,7.962,0,10c0,5.522,4.477,10,10,10c2.036,0,3.93-0.613,5.512-1.66c1.121-0.742,2.085-1.706,2.828-2.828C19.387,13.931,20,12.038,20,10C20,4.478,15.522,0,10,0z M10,16c-3.31,0-6-2.691-6-6c0-0.923,0.227-1.788,0.6-2.572l7.97,7.971C11.788,15.772,10.923,16,10,16zM7.428,4.6C8.211,4.228,9.076,4,10,4c3.308,0,6,2.691,6,6c0,0.923-0.228,1.788-0.602,2.571L7.428,4.6z'
                            className='style-scope bc-icon'></path>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:alert-triangle-stroked') {
            return (
                <svg viewBox='0 0 23 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <path
                                    d='M11.288,2.015l9.373,16.236H1.914L11.288,2.015 M11.288,0C10.759,0,10.23,0.347,9.83,1.042L0.342,17.476C-0.46,18.863,0.196,20,1.8,20h18.976c1.604,0,2.26-1.137,1.458-2.524L12.745,1.042C12.345,0.347,11.816,0,11.288,0L11.288,0z'
                                    className='style-scope bc-icon'></path>
                            </g>
                            <g className='style-scope bc-icon'>
                                <g className='style-scope bc-icon'>
                                    <path
                                        d='M11.96,13.605h-1.358L10.14,6.54h2.281L11.96,13.605z M12.38,15.803c0,0.309-0.107,0.569-0.322,0.783c-0.215,0.215-0.471,0.322-0.77,0.322c-0.308,0-0.569-0.107-0.784-0.322c-0.215-0.214-0.322-0.475-0.322-0.783c0-0.308,0.107-0.568,0.322-0.783s0.476-0.322,0.784-0.322c0.299,0,0.555,0.107,0.77,0.322S12.38,15.495,12.38,15.803z'
                                        className='style-scope bc-icon'></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:chart-line') {
            return (
                <svg viewBox='0 0 25 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <rect y='18' width='25' height='2' className='style-scope bc-icon'></rect>
                            <circle cx='0.5' cy='0.5' r='0.5' className='style-scope bc-icon'></circle>
                            <circle cx='0.5' cy='5.125' r='0.5' className='style-scope bc-icon'></circle>
                            <circle cx='0.5' cy='9.75' r='0.5' className='style-scope bc-icon'></circle>
                            <circle cx='0.5' cy='14.375' r='0.5' className='style-scope bc-icon'></circle>
                            <path
                                d='M2.859,12.875c-0.182,0-0.357-0.1-0.445-0.273c-0.126-0.246-0.028-0.547,0.218-0.672L9.88,8.233c0.125-0.064,0.271-0.072,0.404-0.022l5.043,1.905l8.239-5.963c0.223-0.162,0.536-0.112,0.698,0.112c0.162,0.224,0.111,0.536-0.112,0.698l-8.457,6.121c-0.136,0.099-0.312,0.121-0.47,0.062l-5.088-1.922L3.087,12.82C3.014,12.857,2.937,12.875,2.859,12.875z'
                                className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:chevron-double-thick-e') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <polygon points='5.077,10 0,4.923 0,0 10,10 0,20 0,15.077'
                                className='style-scope bc-icon'></polygon>
                            <polygon points='14.308,10 9.231,4.923 9.231,0 19.23,10 9.231,20 9.231,15.077'
                                className='style-scope bc-icon'></polygon>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:edit') {
            const { color } = this.props
            return (
                <svg viewBox='0 0 18 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle} fill={color ? color : '#1165e5'}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <g className='style-scope bc-icon'>
                                <rect x='9.968' y='1.625' transform='matrix(0.7072 0.707 -0.707 0.7072 5.5532 -8.1972)'
                                    width='5.41' height='1.962' className='style-scope bc-icon'></rect>
                                <polygon points='8.68,2.773 1.389,10.065 1.387,10.065 0,15.278 5.213,13.892 12.504,6.6'
                                    className='style-scope bc-icon'></polygon>
                            </g>
                            <rect y='17.5' width='16.667' height='2.5' className='style-scope bc-icon'></rect>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:checkmark-square-unchecked') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-icon'>
                        <g className='style-scope bc-icon'>
                            <path d='M19,1v18H1V1H19 M20,0H0v20h20V0L20,0z' className='style-scope bc-icon'></path>
                        </g>
                    </g>
                </svg>
            );
        }
        else if (type && type === 'by-icon:checkmark-square-checked') {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}>
                    <g className='style-scope bc-ico'>
                        <path
                            d='M0,0v20h20V0H0z M8.13,16.014l-0.841,0.84l-0.84-0.84l-4.803-4.803L3.33,9.526l4.022,4.023l9.963-9.964l1.621,1.622L8.13,16.014z'
                            className='style-scope bc-icon'></path>
                    </g>
                </svg>
            );
        }
        else {
            return (
                <svg viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' className='style-scope bc-icon'
                    style={svgStyle}></svg>
            );
        }
    }

    generateBySvgHeight(svgSize) {
        let finalHeight = -1;
        switch (svgSize) {
            case 'xxl':
                finalHeight = 100;
                break;
            case 'xl':
                finalHeight = 50;
                break;
            case 'l':
                finalHeight = 30;
                break;
            case 'default':
                finalHeight = 20;
                break;
            case 's':
                finalHeight = 15;
                break;
            case 'xs':
                finalHeight = 10;
                break;
            case 'xxs':
                finalHeight = 5;
                break;
            case 'xxxs':
                finalHeight = 3;
        }
        if (finalHeight < 0) {
            if (!isNaN(svgSize)) {
                finalHeight = Number(svgSize);
            }
        }
        if (finalHeight < 0) {
            finalHeight = 16;
        }
        return finalHeight;
    }

    generateBySvgWidth(type, height) {
        let width = height;
        switch (type) {
            case 'by-icon:triangle-n':
                width = height * 21 / 20;
                break;
            case 'by-icon:triangle-s':
                width = height * 21 / 20;
                break;
            case 'by-icon:profile':
                width = height * 21 / 20;
                break;
            case 'by-icon:chevron-thin-w':
                width = height / 2.5;
                break;
            case 'by-icon:chevron-thin-e':
                width = height / 2.5;
                break;
            case 'by-icon:chevron-thin-s':
                width = height * 2.5;
                break;
            case 'by-icon:chevron-thin-n':
                width = height * 2.5;
                break;
            case 'sort-trig-up':
                width = height * 4 / 7;
                break;
            case 'sort-trig-down':
                width = height * 4 / 7;
                break;
            case 'sort-trig-default':
                width = height * 4 / 7;
                break;
            case 'by-logo:logo-citi-reverse-red':
                width = height / 20 * 35;
                break;
            case 'by-logo:logo-citi-2-color':
                width = height / 20 * 35;
                break;
            case 'by-custom-color:file-pdf-red':
                width = height / 20 * 16;
                break;
            case 'by-custom-color:file-excel-green':
                width = height / 20 * 16;
                break;
            case 'by-custom-color:file-word-doc-blue':
                width = height / 20 * 16;
                break;
            case 'by-custom-color:document-generic-file-gray':
                width = height / 20 * 16;
                break;
            case 'by-icon:calendar-dots':
                width = height * 21 / 20;
                break;
            case 'by-icon:alert-triangle':
                width = height * 23 / 20;
                break;
            case 'by-icon:alert-triangle-stroked':
                width = height * 23 / 20;
                break;
            case 'by-icon:chart-line':
                width = height * 25 / 20;
                break;
            case 'by-icon:edit':
                width = height * 18 / 20;
                break;
            case 'by-icon:checkmark-square-unchecked':
                width = height * 20 / 20;
                break;
            case 'by-icon:checkmark-square-checked':
                width = height * 20 / 20;
                break;
            default:
                width = height;
                break;
        }
        width = !isNaN(width) ? width.toFixed(0) : height;
        return Number(width);
    }

    onSvgClick(e) {
        this.props.onClick(e);
    }
}

SvgGenerator.defaultProps = {
    type: '',
    top: '-1px',
    svgSize: 'default',
    svgClass: '',
    svgId: '',
    show: true,
    onClick: function () {
    },
    style: undefined
};

SvgGenerator.propTypes = {
    type: PropTypes.string.isRequired,
    top: PropTypes.string,
    svgSize: PropTypes.string,
    svgClass: PropTypes.string,
    svgId: PropTypes.string,
    show: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
};



import React, { Component } from 'react';

import Grid from '../grid/Grid';
import './Page.less'

class Page extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            page: props.page,
        }
    }

    render() {
        const { page } = this.state;
        console.log(page);

        return (
            <div className="Page">
                <Grid>
                    {
                        page&&page.map((row, index) =>  (
                        <Grid.Row key={index} alignment={row.alignment}>
                            {row.uiCols.map((col, index) => ( <Grid.Col key={index} width={col.width} portlet={col.portlet} leafFlag={col.leafFlag} uiRows={col.uiRows} portlet={col.portlet}></Grid.Col> ))}
                        </Grid.Row>))
                    }
                </Grid>
            </div>
        );
    }
}

export default Page;
import $ from 'jquery'
// import Promise from 'promise'
import React from 'react'
import SvgGenerator from '../svgGenerator/svgGenerator'

export default {
    fetch: function (url, method, parms) {
        let fullUrl = this.transferUrl(url)
        let promise = new Promise(function (resolve, reject) {
            $.ajax({
                method: method,
                url: fullUrl,
                data: (!parms ? {} : parms)
            }).done(resolve).fail(reject);
        });
        return promise;
    },

    descToVal: function (data) {
        let newData = data.map((item) => {
            let newItem = {};
            for (let key in item) {
                if (key == 'description') {
                    newItem.value = item.description;
                } else {
                    newItem[key] = item[key];
                }
            }
            return newItem;
        });
        return newData;
    },

    transferUrl: function (url) {
        let fullUrl = this.dynamicallyUpdateURL(url);
        let pathName = window.location.pathname;
        if (pathName && url) {
			let currentHref = pathName.split('/')[1];
            fullUrl = "/".concat(currentHref, "", fullUrl);

        }
        return fullUrl;
    },

    /**
     * This method is to help back-end service refactoring, ajax call can dynamically update the url.
     *@param {string} url - This is the url to be update.
     */
    dynamicallyUpdateURL: function (url) {
        let urlArr = url.split('/');
        urlArr.splice(3, 0, 'v1');
        return urlArr.join('/');
    },

    getUserInfo: function () {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let [regionId, investorTypeId, profileRelation] = [-1, -1, undefined];
        if (userInfo) {
            [regionId, investorTypeId, profileRelation] = [userInfo.region, userInfo.userType, userInfo.profileRelation];
        }
        return {regionId: regionId, investorTypeId: investorTypeId, profileRelation: profileRelation}
    },

    getIconByType(fileType, size) {
        let icon;
        if (fileType == 'pdf') {
            icon = <SvgGenerator type='by-custom-color:file-pdf-red' svgClass='by-svg-icon' svgSize={size} ></SvgGenerator>
        } else if (fileType == 'xls' || fileType == 'xlsx') {
            icon = <SvgGenerator type='by-custom-color:file-excel-green' svgClass='by-svg-icon' svgSize={size} ></SvgGenerator>
        } else if (fileType == 'docx' || fileType == 'doc') {
            icon = <SvgGenerator type='by-custom-color:file-word-doc-blue' svgClass='by-svg-icon' svgSize={size} ></SvgGenerator>
        } else {
            icon = <SvgGenerator type='by-custom-color:document-generic-file-gray' svgClass='by-svg-icon' svgSize={size} ></SvgGenerator>
        }
        return icon;
    },

    getCurrentYear() {
        const currentDate = new Date();
        return currentDate.getFullYear();
    },

    // verifyRegionAndInvestorType(regionIdFromUrl,investorTypeIdFromUrl){
    //     const {regionId, investorTypeId, profileRelation} = this.getUserInfo();
    //     if (regionId === "" || investorTypeId === "" || Number(regionId) != Number(regionIdFromUrl) || Number(investorTypeId) != Number(investorTypeIdFromUrl) || !profileRelation) {
    //         browserHistory.push('/');
    //     }
    // }
}
import React, {useState} from 'react';
import './cisVideo.css';
import {LEGAL_INFO_MAPPING, OPEN_LEGAL_INFO, CLOSE_LEGAL_INFO} from '../../utils/Constant'

const CisVideo = ({ videoLink, onClose, isShowVideo }) => {
    const [showLegal, setShowLegal] = useState(false)
    const isMP4 = videoLink && videoLink.includes('.mp4')
    const splitedVideoLinkArr = (videoLink && videoLink.split('.com')) || []
    const videoLinkSuffix =splitedVideoLinkArr[1] || ''
    const legalInfo = LEGAL_INFO_MAPPING[videoLinkSuffix]
    const PC_VIDEO_PARAMETER = `?autoPlay=true&autoscale=true&embed=true&host=widgets&infoposition=left&AppProperties.PlayerShowEmbedButton=false&aspect=16:9&ri=amgyNzExN3x8fFZJRFNTQVVhWTA5d3VFfHx8Nzl8Q1YtTWFya2V0QnV6enwiIg==`;

    const onCloseVideo = () => {
        setShowLegal(false)
        onClose()
    }
    const onLegalIconClick = () => {
        setShowLegal(!showLegal)
    }
    return (
        <div className={isShowVideo ? "show-modal" : "hidden-modal"}>
            <div className="modal-content-incis">
                <span className="close" onClick={onCloseVideo}>X</span>
               {legalInfo &&  
                    <div className='legal-icon-wrapper' onClick={onLegalIconClick} title={showLegal? CLOSE_LEGAL_INFO: OPEN_LEGAL_INFO}>
                        <span className={`fa fa-fw ${showLegal? 'fa-times':'fa-bars'}`}></span>
                    </div>
                }
                {showLegal &&  
                    <div className='legal-info'>
                        <div className='legal-info__header'>
                            <div className='legal-info__title'>Info</div>
                        </div>
                        <div className='legal-info__body'>
                            <div className='legal-info_field'>Legal</div>
                            <div className='legal-info__text'> 
                                {legalInfo}
                            </div>
                        </div>   
                    </div>
                }
                { isShowVideo && (isMP4 ?
                    <video controls autoPlay className="video">
                        <source src={videoLink} type="video/mp4" />
                    </video>:
                    <iframe
                        // className="videoIframe"
                        src={videoLink+PC_VIDEO_PARAMETER}
                        title={videoLink}
                        frameBorder="0"
                        allow="autoplay; encrypted-media; fullscreen"
                        allowFullScreen
                        className="iframe-cis-video"
                    />
                    )
                }
            </div>
        </div>
    )
}

export default CisVideo;
import React, { useEffect, useState, useCallback } from 'react'
import './CookieAccept.less'
const CITI_ACCEPT_COOKIE = "CITI_ACCEPT_COOKIE"

const CookieAccept = (props) => {

    const [enabledCookie, setEnableCookie] = useState(false)
    let classes = "cookie-notification"
    const {onControl} = props;
    if (enabledCookie) {
        classes += "  cookie-notification--enabled"
    }

    useEffect(() => {
        const allCookies = document.cookie.match('(^|;) ?' + CITI_ACCEPT_COOKIE + '=([^;]*)(;|$)'),
            acceptCookiesCookie = allCookies ? allCookies[2] : null,
            acceptCitiStorage = window.localStorage.getItem(CITI_ACCEPT_COOKIE)

        if (!acceptCookiesCookie && !acceptCitiStorage && ! props.isCookieNotificationOpen) {
            setEnableCookie(true); 
            typeof onControl==='function'&& onControl(true)
        } 

    })

    const onAcceptCookie = useCallback(() => {
        setEnableCookie(false)
        window.localStorage.setItem(CITI_ACCEPT_COOKIE, true)
        const newCookie = CITI_ACCEPT_COOKIE + "=" + true
        document.cookie = newCookie
        closeCookieNotification();
    })
    const closeCookieNotification = (props) => {        
        typeof onControl === 'function'&& onControl(false);
    }
    const onDenyCookie = useCallback(() => {
        setEnableCookie(false)
        window.localStorage.setItem(CITI_ACCEPT_COOKIE, false)
        const newCookie = CITI_ACCEPT_COOKIE + "=" + false
        document.cookie = newCookie
        closeCookieNotification();
    })

    return (
        <div className={classes}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="content">
                            <div className="header-container">
                                <p >We use cookies</p>
                                <img onClick={onDenyCookie} src="/cis/akpublic/images/close-grey.svg" alt="close"/>
                            </div>
                            <div >
                                <p>We use cookies to give you the best experience on our website. By continuing to browse the site, you are agreeing to our use of cookies. You can change your cookie settings at any time but if you do, you may lose some functionality. More information can be found in our&nbsp; 
                                    <a target="_blank" href="/cis-terms-conditions">Terms & Conditions</a> and <a target="_blank" href="/cis-privacy">Privacy</a>.</p>
                            </div>
                            <button onClick={onAcceptCookie} className="cookie-notification_accept">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookieAccept
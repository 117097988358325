import React from 'react';
import ReactDataGrid from 'react-data-grid/dist/react-data-grid.min.js';
import Utils from '../cisGrid/utils';
import LoadingMask from '../cisGrid/loadingmask';
import DateUtils from '../../utils/DateUtils';
import PieChart from './PieChart';
import HtmlFormatter from '../cisCommon/htmlFormatter';
import { Tooltip } from 'antd';
import moment from 'moment';

class Composition extends React.Component {
	componentWillMount() {
		const { regionId, investorTypeId } = Utils.getUserInfo();
		const { dispatch, mqaTicker, fetchIndicesDetailCP } = this.props;
		fetchIndicesDetailCP(mqaTicker, regionId, investorTypeId, dispatch);
	}

	downloadPdfClick() {
		const url = this.getPdfUrl();
		url && window.open(url);
	}

	getPdfUrl() {
		const { regionId, investorTypeId } = Utils.getUserInfo();
		const { dispatch, mqaTicker } = this.props;
		let Url =
			'/cis/ui-service/composition/download/' +
			mqaTicker +
			'?investorTypeId=' +
			investorTypeId +
			'&regionId=' +
			regionId;
		let port = window.location.port;
		port = port ? ':' + port : port;
		Url =
			window.location.protocol +
			'//' +
			window.location.hostname +
			port +
			Utils.transferUrl(Url);
		return Url;
	}

	getPieChart = (data, title) => {
		return (
			data &&
			Object.keys(data).length && (
				<div className="w50p ib">
					<PieChart title={title} key={title} data={data} />
				</div>
			)
		);
	};

	render() {
		const {
			compData,
			isFetchingIndicesComposition,
			asOfDate,
			countryMap,
			sectorMap,
			showTop10Composition
		} = this.props;
		let items;
		if (compData) {
			if (compData.length === 0) {
				items = (
					<tr className="by-row">
						<td className="by-col">
							<span className="composition-nodata-td">
								Index Composition is not available.
							</span>
						</td>
						<td className="by-col tl"></td>
						<td className="by-col tl"></td>
					</tr>
				);
			} else {
				items = compData.map((item, index) => {
					return (
						<tr key={index} className="by-row">
							<td className="by-col tl">{item.name}</td>
							<td className="by-col tl">{item.bbgTicker}</td>
							<td className="by-col tl">{item.percentStr}</td>
						</tr>
					);
				});
			}
		}

		const TABLE_CLASS_NAME =
			'cis-table-blue by-table by-table-striped by-table-around-bordered by-table-hover by-table-l';
		return (
			<div className="pr min-h-50 composition-detail-page">
				<button
					className="by-button by-bluefill by-xl cis-home-viewIndices-hide"
					onMouseUp={this.downloadPdfClick.bind(this)}
				>
					DOWNLOAD-PDF/1.2
				</button>
				<LoadingMask show={isFetchingIndicesComposition}></LoadingMask>
				<div>
					{this.getPieChart(sectorMap, 'SECTOR WEIGHTS')}
					{this.getPieChart(countryMap, 'COUNTRY WEIGHTS')}
				</div>
				<table className={TABLE_CLASS_NAME}>
					<thead className="by-header">
						<tr className="by-row">
							<th className="by-col tl">NAME</th>
							<th className="by-col tl">BLOOMBERG TICKER / CITI IDENTIFIER</th>
							<th className="by-col tl">{`WEIGHTS (${showTop10Composition?'top 10, ':''}as of ${DateUtils.formatDateCis(
								asOfDate
							)})`}</th>
						</tr>
					</thead>
					<tbody className="by-body">{items}</tbody>
				</table>
				{asOfDate &&
					compData.length > 0 && [
						<hr key="hr" />,
						<p key="p">
							<b>
								Data for all charts, graphs and tables are as of{' '}
								{DateUtils.formatDateCis(asOfDate)}. Data source for all charts,
								graphs and tables: Citi / Bloomberg.
							</b>
						</p>
					]}
			</div>
		);
	}
}
class TextFormatter extends React.Component {
	render() {
		return (
			<span
				style={{
					textAlign: 'left',
					display: 'inline-block',
					width: '100%'
				}}
			>
				{this.props.value}
			</span>
		);
	}
}
class Header extends React.Component {
	render() {
		const { displayName, explain } = this.props;
		return (
			<span >
				<span title = {displayName} className='header-formatter'>{displayName}{' '}</span>
				{explain && (
					<Tooltip title={explain} color={'#108ee9'}>
						<span className="question-mark">?</span>
					</Tooltip>
				)}
			</span>
		);
	}
}

export default Composition;

import React from 'react'
import RetailLink from '../commons/retailLink/RetailLink';
import './LogoBar.less'

const LogoBar = ({collapsed,toggleBtn,clickMobileLogo,searchHide, show})=> {
    const toggleBtnClass = collapsed ? 'hamburger' : 'hamburger x';
    return (
        <div className="logoBarFloating" style={{display:show?'block':'none'}}>
            <div
                className="logoBar" >
                <div className="container container-custom">
                    <button
                        className="toggleBtn"
                        type="button"
                        data-toggle="collapse"
                        data-target="#menuCollapse"
                        aria-expanded="false"
                        aria-controls="menuCollapse"
                        onClick={toggleBtn}
                    >
                        <span className="navbar-toggler-icon">
                            <span className={toggleBtnClass}>
                                <span />
                                <span />
                                <span />
                            </span>
                        </span>
                    </button>
                    <img className="logo"
                            src="/cis/akpublic/images/citi.svg"
                            alt="Citi"
                            title="Citi Home"
                        />
                    {/* <img className="brand" src="/images/capital-market-solutions-outlined-new.svg" alt="Capital Markets Solutions" /> */}
                    <svg className="brand" xmlns="http://www.w3.org/2000/svg" width="350" height="30" viewBox="0 0 350 30" onClick={searchHide}>
                            <g fill="#FFF" fillRule="evenodd" fontFamily="Interstate,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif" transform="translate(-1 -3)">
                                <text fontSize="20" letterSpacing=".791">
                                    <tspan x="30" y="25">Citi Investment Strategies(CIS)</tspan>
                                </text>
                            </g>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default LogoBar
